<template>
  <div>
    <ValidationProvider
      v-slot="{ errors }"
      name="users"
      :rules="{ required: true }"
    >
      <!-- user -->

      <MultiSelectField
        label="users"
        is-mandatory
        :value="users"
        :options="userList"
        :error="errors[0]"
        @input="updateUsers"
      />

      <!-- ... -->
    </ValidationProvider>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";

export default {
  name: "Users",

  components: { ValidationProvider, MultiSelectField },

  props: {
    users: {
      type: Array,
      required: true,
    },

    userList: {
      type: Array,
      required: true,
    },
  },

  methods: {
    updateUsers(users) {
      this.$parent.$emit("update:users", users);
    },
  },
};
</script>

<style lang="scss" scoped>
.options-wrapper {
  border-top: 1px solid var(--divider-color);
  padding-top: 16px;
}
</style>
