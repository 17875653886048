<template>
  <div>
    <!-- initiate by -->

    <FormFieldLabel label="initiate by" is-mandatory />

    <div class="options-wrapper">
      <template v-if="initiateMode.includes('MANUAL')">
        <!-- user -->

        <CheckboxField
          :value="value.includes('USER')"
          label="User"
          description="A licensed internal or external user in the system"
          class="q-mb-md"
          @input="updateValue('USER')"
        />

        <!-- users -->

        <Users
          v-if="value.includes('USER')"
          :users="users"
          :user-list="userList"
          class="field q-mb-md"
        />

        <!-- ... -->

        <!-- ... -->

        <!-- user -->

        <CheckboxField
          :value="value.includes('DOMAIN_USER')"
          label="Domain User"
          description="Accessible to domain users"
          class="q-mb-md"
          @input="updateValue('DOMAIN_USER')"
        />

        <!-- domain users -->
        <ValidationProvider
          v-slot="{ errors }"
          name="domain"
          :rules="{ domain: true }"
        >
          <MultiSelectField
            v-if="value.includes('DOMAIN_USER')"
            :value="userDomains"
            label="Domain Name"
            is-mandatory
            :options="userDomainList"
            new-option
            :is-clearable="true"
            class="field q-mb-md"
            placeholder="example.com"
            :error="errors[0]"
            @input="updateUserDomain"
          />
        </ValidationProvider>

        <!-- ... -->

        <!-- ... -->

        <!-- group -->

        <CheckboxField
          :value="value.includes('GROUP')"
          label="Group"
          description="A group of users under a preconfigured group"
          class="q-mb-md"
          @input="updateValue('GROUP')"
        />

        <Groups
          v-if="value.includes('GROUP')"
          :groups="groups"
          :group-list="groupList"
          class="field q-mb-md"
        />

        <!-- ... -->

        <!-- portal users -->

        <CheckboxField
          :value="value.includes('PORTAL')"
          label="Portal Submission"
          description="Start your workflow using a web portal"
          class="q-mb-md"
          @input="updateValue('PORTAL')"
        />

        <!-- ... -->
      </template>

      <template v-if="initiateMode.includes('AUTOMATIC')">
        <!-- email -->

        <CheckboxField
          :value="value.includes('EMAIL')"
          label="Email"
          description="Start your workflow from an incoming email"
          class="q-mb-md"
          @input="updateValue('EMAIL')"
        />
        <!--  @input="updateMailInitiate" -->
        <EmailInitiator
          v-if="value.includes('EMAIL') && initiateMode.includes('AUTOMATIC')"
          v-model="mailInitiate"
          :mail-settings-list="mailSettingsList"
          :mail-domain-list="mailDomainList"
          :user-email-list="userEmailList"
          :mail-trigger-conditions.sync="mailTriggerConditions"
          :has-mail-subject="hasMailSubject"
          :has-mail-content="hasMailContent"
          form-component="initiateBy"
          class="field"
        />

        <!-- ... -->

        <!-- form -->

        <CheckboxField
          :value="value.includes('MASTER_FORM')"
          label="Master form submission"
          description="Start your workflow using a web from"
          class="q-mb-md"
          @input="updateValue('MASTER_FORM')"
        />

        <SelectField
          v-if="value.includes('MASTER_FORM')"
          :value="masterFormId"
          is-mandatory
          label="Master Form"
          :options="masterFormList"
          :is-clearable="true"
          class="field q-mb-md"
          @input="updateMasterFormId"
        />

        <!-- ... -->

        <!-- document -->

        <CheckboxField
          :value="value.includes('DOCUMENT')"
          label="Document upload"
          description="Start your workflow from a document upload"
          class="q-mb-md"
          @input="updateValue('DOCUMENT')"
        />

        <!-- ... -->

        <!-- External API -->

        <CheckboxField
          :value="value.includes('EXTERNAL_SERVICE')"
          label="External Service"
          description="Start your workflow using an external service"
          class="q-mb-md"
          @input="updateValue('EXTERNAL_SERVICE')"
        />

        <!-- ... -->
      </template>
    </div>

    <FormFieldError v-if="error" :error="error" />

    <!-- ... -->
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import Users from "./Users.vue";
import Groups from "./Groups.vue";
import EmailInitiator from "./EmailInitiator.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";

import { ValidationProvider } from "vee-validate";

export default {
  name: "InitiateBy",

  components: {
    FormFieldLabel,
    CheckboxField,
    FormFieldError,
    Users,
    Groups,
    ValidationProvider,
    MultiSelectField,
    EmailInitiator,
    SelectField,
  },

  props: {
    value: {
      type: Array,
      required: true,
    },

    initiateMode: {
      type: String,
      default: "MANUAL",
    },

    error: {
      type: String,
      default: "",
    },

    // users: {
    //   type: Array,
    //   default: () => [],
    // },

    userList: {
      type: Array,
      default: () => [],
    },

    userDomainList: {
      type: Array,
      default: () => [],
    },

    userDomains: {
      type: Array,
      default: () => [],
    },

    groupList: {
      type: Array,
      default: () => [],
    },

    mailSettingsList: {
      type: Array,
      default: () => [],
    },

    mailDomainList: {
      type: Array,
      default: () => [],
    },

    userEmailList: {
      type: Array,
      default: () => [],
    },

    mailTriggerConditions: {
      type: Array,
      default: () => [],
    },

    hasMailContent: {
      type: Boolean,
      default: false,
    },

    hasMailSubject: {
      type: Boolean,
      default: false,
    },

    mailInitiateNew: {
      type: Object,
      default: () => {},
    },

    masterFormList: {
      type: Array,
      default: () => [],
    },

    masterFormId: {
      type: Number,
      default: 0,
    },

    users: {
      type: Array,
      default: () => [],
    },

    groups: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      mailInitiate: {
        mailSettingsId: 0,
        conditions: {
          hasAttachment: false,
          attachment: {
            filename: "",
            fileType: "",
            ocrData: "",
          },
          mailSubject: "",
          mailContent: "",
          fromAddress: [],
          fromDomain: [],
        },
      },
    };
  },

  watch: {
    mailInitiate: {
      deep: true,
      // immediate: true,
      handler() {
        this.$emit("update:mailInitiate", this.mailInitiate);
      },
    },

    mailInitiateNew: {
      // deep: true,
      immediate: true,
      handler() {
        if (this.mailInitiateNew && this.mailInitiateNew.mailSettingsId) {
          this.mailInitiate = this.mailInitiateNew;
        }
      },
    },
  },

  methods: {
    updateValue(option) {
      const _value = [...this.value];

      if (_value.includes(option)) {
        const optionIdx = _value.indexOf(option);
        _value.splice(optionIdx, 1);
      } else {
        _value.push(option);
      }

      this.$emit("input", _value);
    },

    updateUserDomain(value) {
      this.$emit("update:userDomains", value);
    },

    updateMasterFormId(value) {
      this.$emit("update:masterFormId", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.options-wrapper {
  border-top: 1px solid var(--divider-color);
  padding-top: 16px;
}
</style>
