<template>
  <div id="rules-header">
    <div class="avatar">R{{ ruleIdx }}</div>

    <BaseSeparator is-vertical has-inset class="q-mx-md" />

    <!-- rule name -->

    <div class="rule-name">
      <input v-model="rule.name" placeholder="Rule name" />
    </div>

    <!-- ... -->

    <BaseSeparator is-vertical has-inset class="q-mx-md" />

    <!-- delete rule -->

    <BaseIcon
      v-tooltip:red.top="'delete rule'"
      name="eva-trash-2-outline"
      color="red"
      class="cursor-pointer"
      @click="$emit('delete')"
    />

    <!-- ... -->
  </div>
</template>

<script>
export default {
  name: "RulesHeader",

  props: {
    ruleIdx: {
      type: Number,
      default: 0,
    },

    rule: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
#rules-header {
  display: flex;
  align-items: center;

  .avatar {
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    color: white;
    background-color: var(--secondary);
  }

  .rule-name {
    flex: 1;
  }
}
</style>
