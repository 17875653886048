<template>
  <Modal :value="value" width="480px" @input="closeModal">
    <!-- title -->

    <template #title>add blocks</template>

    <!-- ... -->

    <template #action>
      <expandableSearch @search="filterList"></expandableSearch>
    </template>

    <template #default>
      <div id="workflow-blocks">
        <div
          v-for="block in searchText ? filterBlocks : _blocks"
          :key="block.id"
          class="block"
          @click="addBlock(block.type)"
        >
          <div class="icon-wrapper" :style="{ backgroundColor: block.color }">
            <BaseIcon :name="block.icon" is-white />
          </div>

          <div class="col">
            <div class="label">{{ block.label }}</div>
            <div class="description">{{ block.description }}</div>
          </div>

          <div class="counter">{{ block.counter }}</div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/common/popup/Modal.vue";
import expandableSearch from "@/components/common/ExpandableSearch.vue";
import blockList from "../../../helpers/blockList.js";

export default {
  name: "AddBlocksModal",

  components: { Modal, expandableSearch },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    blocks: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      filterBlocks: [],
      searchText: "",
    };
  },

  computed: {
    _blocks() {
      const _blocks = Object.keys(blockList);
      return _blocks.map((_block) => ({
        id: this.$nano.id(),
        type: _block,
        label: blockList[_block].label,
        description: blockList[_block].description,
        color: blockList[_block].color,
        icon: blockList[_block].icon,
        counter: this.blocks.filter((block) => block.type === _block).length,
      }));
    },
  },

  methods: {
    closeModal() {
      this.$emit("input", false);
    },

    addBlock(blockType) {
      this.$emit("addBlock", blockType);
      this.closeModal();
    },

    filterList(text) {
      this.searchText = text;
      this.filterBlocks = this._blocks.filter(
        (_block) =>
          _block.label.toLowerCase().indexOf(this.searchText.toLowerCase()) >
            -1 ||
          _block.description
            .toLowerCase()
            .indexOf(this.searchText.toLowerCase()) > -1
      );
    },
  },
};
</script>

<style lang="scss" scoped>
#workflow-blocks {
  .block {
    display: flex !important;
    align-items: center;
    padding: 12px 16px;
    margin: 0px -16px;
    user-select: none;
    transition: all 0.3s ease;

    &:hover {
      background-color: var(--hover-bg-color);
      cursor: pointer;
    }

    .icon-wrapper {
      height: 36px;
      width: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      margin-right: 16px;
    }

    .label {
      font-weight: 500;
      text-transform: capitalize;
      flex: 1;
    }

    .description,
    .counter {
      @extend .text-sm;
      color: var(--icon-color-inverted);
    }

    .counter {
      margin-right: 12px;
    }
  }
}
</style>
