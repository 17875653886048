<template>
  <div id="reminder-builder" class="field">
    <div class="options-wrapper">
      <FormFieldLabel
        label="Configure alerts for document nearing their expiry dates. Users can set the frequency and timing of 
        notifications, ensuring they receive timely reminders. This helps in managing document
         renewals and avoiding lapses in validity."
        transform=""
        class="field"
      />

      <BaseScrollbar>
        <div>
          <FormFieldLabel label="Document Checklist" is-mandatory />
          <table>
            <thead>
              <tr>
                <th class="medium">Document Field</th>
                <th class="medium">Expiry Field</th>
                <th class="small">Reminder</th>
                <th class="action">
                  <BaseActionButton
                    is-flat
                    class="q-ml-xs"
                    color="secondary"
                    icon="eva-plus-outline"
                    no-border
                    @click="addRow"
                  />
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(row, index) in value.documentList" :key="index">
                <td>
                  <SelectField
                    v-model="value.documentList[index]['documentField']"
                    class="col"
                    :options="formFields"
                    :is-clearable="false"
                  />
                </td>
                <td>
                  <SelectField
                    v-model="value.documentList[index]['expiryField']"
                    class="col"
                    :options="formFields"
                    :is-clearable="false"
                  />
                </td>

                <td class="custom-center">
                  <BaseActionButton
                    v-tooltip.top="'reminder'"
                    is-flat
                    color="grey"
                    icon="eva-settings-outline"
                    class="autoMargin"
                    no-border
                    @click="setReminder(index)"
                  />
                </td>
                <td class="action">
                  <BaseActionButton
                    is-flat
                    color="red"
                    icon="eva-close-outline"
                    no-border
                    @click="removeRow(index)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </BaseScrollbar>

      <Modal
        v-model="showReminderModal"
        width="70vw"
        height="80vh"
        has-footer
        no-padding
        @input="showReminderModal = false"
      >
        <!-- title -->

        <template #title> Reminder Settings</template>

        <!-- ... -->

        <template #default>
          <div>
            <FormFieldLabel
              label="A document reminder notification alerts users about pending or overdue document submissions. It typically includes details such as the document name, due date, and a call-to-action for timely completion. This helps ensure that required documents are submitted on schedule."
              transform=""
              class="q-my-md"
            />
            <table>
              <thead>
                <tr>
                  <th class="medium">Days Before Expiry</th>
                  <th class="medium">Notify Users</th>
                  <th class="medium">Notify To</th>
                  <th class="small">Initiator</th>
                  <th class="small">Superuser</th>
                  <th class="small">Coordinator</th>
                  <th class="action">
                    <BaseActionButton
                      is-flat
                      class="q-ml-xs"
                      color="secondary"
                      icon="eva-plus-outline"
                      no-border
                      @click="addRowReminder"
                    />
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(row, index) in reminder" :key="index">
                  <td>
                    <div class="row">
                      <NumberField
                        v-model="reminder[index]['duration']"
                        :is-clearable="false"
                        class="col-4"
                      />
                      <SelectField
                        v-model="reminder[index]['type']"
                        :options="durationOption"
                        :is-clearable="false"
                        class="col"
                      />
                    </div>
                  </td>
                  <td>
                    <MultiSelectField
                      v-model="reminder[index]['notifyUsers']"
                      class="col"
                      :options="userList"
                      :is-clearable="false"
                    />
                  </td>
                  <td>
                    <MultiSelectField
                      v-model="reminder[index]['notifyFormColumn']"
                      class="col"
                      :options="formFields"
                      :is-clearable="false"
                    />
                  </td>
                  <td class="custom-center">
                    <CheckboxField v-model="reminder[index]['initiator']" />
                  </td>
                  <td class="custom-center">
                    <CheckboxField v-model="reminder[index]['superuser']" />
                  </td>
                  <td class="custom-center">
                    <CheckboxField v-model="reminder[index]['coordinator']" />
                  </td>
                  <td class="action">
                    <BaseActionButton
                      is-flat
                      color="red"
                      icon="eva-close-outline"
                      no-border
                      @click="removeRowReminder(index)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <template v-if="false">
              <FormFieldLabel label="Subject" class="field q-mt-md" />

              <TextEditor
                v-model="reminderSubject"
                :hide-tool-bar="false"
                :form-columns="formFields"
              ></TextEditor>

              <FormFieldLabel label="Content" class="field q-mt-md" />

              <TextEditor
                v-model="reminderContent"
                :form-columns="formFields"
              ></TextEditor>
            </template>

            <CheckboxField
              v-model="hasAttachment"
              label="Include attachments"
              description="Include attachments while sending the notification"
              class="field q-my-md"
            />
          </div>
        </template>
        <template #footer>
          <BaseButton label="Save" color="primary" @click="saveSettings" />
        </template>
      </Modal>
    </div>
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import Modal from "@/components/common/popup/Modal.vue";
import NumberField from "@/components/common/form/number-field/NumberField.vue";
import TextEditor from "./components/TextEditor";

export default {
  name: "DocumentReminder",

  components: {
    FormFieldLabel,
    MultiSelectField,
    CheckboxField,
    SelectField,
    Modal,
    NumberField,
    TextEditor,
  },

  props: {
    value: {
      type: Object,
      default: () => {},
    },

    formFields: {
      type: Array,
      default: () => [],
    },

    userList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      showReminderModal: false,
      reminder: [],
      rowIndex: null,
      durationOption: [
        {
          id: this.$nano.id(),
          label: "Days",
          value: "days",
        },
        {
          id: this.$nano.id(),
          label: "Weeks",
          value: "weeks",
        },
        {
          id: this.$nano.id(),
          label: "Months",
          value: "months",
        },
        {
          id: this.$nano.id(),
          label: "Years",
          value: "Years",
        },
      ],
      reminderSubject: {
        text: "",
        content: "",
      },
      reminderContent: {
        text: "",
        content: "",
      },
      hasAttachment: false,
    };
  },

  mounted() {
    if (this.value.documentList.length === 0) {
      this.addRow();
    }
  },

  methods: {
    addRow() {
      const model = {
        id: this.$nano.id(),
        documentField: "",
        expiryField: "",
        reminder: [],
      };

      this.value.documentList.push(model);
    },

    removeRow(rowIdx) {
      this.value.documentList.splice(rowIdx, 1);
    },

    setReminder(index) {
      this.rowIndex = index;
      this.reminder = [];

      if (
        this.value.documentList[index].reminder &&
        this.value.documentList[index].reminder.length
      ) {
        this.reminder = this.value.documentList[index].reminder;
        if (this.value.documentList[index].subject) {
          this.reminderSubject.text = this.value.documentList[index].subject;
          this.reminderSubject.content =
            this.value.documentList[index].subjectContent;
        }
        if (this.value.documentList[index].body) {
          this.reminderContent.text = this.value.documentList[index].body;
          this.reminderContent.content =
            this.value.documentList[index].bodyContent;
        }
        this.hasAttachment = this.value.documentList[index].attachment;
      } else {
        this.value.documentList[index].reminder = [];
        this.value.documentList[index].subject = "";
        this.value.documentList[index].subjectContent = "";
        this.value.documentList[index].body = "";
        this.value.documentList[index].bodyContent = "";
        this.value.documentList[index].attachment = false;
        this.reminderSubject = {
          text: "",
          content: "",
        };
        this.reminderContent = {
          text: "",
          content: "",
        };
        this.hasAttachment = false;
        this.addRowReminder();
      }
      this.showReminderModal = true;
    },

    addRowReminder() {
      const model = {
        duration: 0,
        type: "",
        notifyUsers: [],
        notifyFormColumn: [],
        initiator: false,
        superuser: false,
        coordinator: false,
        level: this.reminder.length + 1,
      };

      this.reminder.push(model);
    },

    removeRowReminder(rowIdx) {
      this.reminder.splice(rowIdx, 1);
    },

    saveSettings() {
      let reminderRow = [];
      if (this.reminder.length) {
        reminderRow = this.reminder.filter(
          (row) =>
            row.duration &&
            row.type &&
            (row.notifyUsers.length ||
              row.notifyFormColumn.length ||
              row.initiator ||
              row.superuser ||
              row.coordinator)
        );
      }
      reminderRow.forEach((row, idx) => {
        row.level = idx + 1;
      });
      this.value.documentList[this.rowIndex].reminder = reminderRow;

      this.value.documentList[this.rowIndex].subject =
        this.reminderSubject.text;
      this.value.documentList[this.rowIndex].subjectContent =
        this.reminderSubject.content;
      this.value.documentList[this.rowIndex].body = this.reminderContent.text;
      this.value.documentList[this.rowIndex].bodyContent =
        this.reminderContent.content;
      this.value.documentList[this.rowIndex].attachment = this.hasAttachment;

      this.rowIndex = null;

      this.reminder = [];
      this.showReminderModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#reminder-builder {
  .option-wrapper {
    border-top: 1px solid var(--divider-color);
  }

  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;

    tr {
      height: 48px;
    }

    th:not(.action) {
      padding: 4px 8px;
      text-align: left;
      font-weight: 500;
      text-transform: capitalize;

      &.small {
        width: 60px;
      }

      &.medium {
        width: 180px;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      &.action {
        width: 36px;
      }
    }

    td {
      padding: 4px;
      .autoMargin {
        margin: auto;
      }
    }
    .custom-center {
      text-align: center;
    }
  }
}

#modal {
  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;

    tr {
      height: 48px;
    }

    th:not(.action) {
      padding: 4px 8px;
      text-align: left;
      font-weight: 500;
      text-transform: capitalize;

      &.small {
        width: 70px;
      }

      &.medium {
        width: 160px;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      &.action {
        width: 36px;
      }
    }

    td {
      padding: 4px;
      .autoMargin {
        margin: auto;
      }
    }
    .custom-center {
      text-align: center;
    }
  }
}
</style>
<style lang="scss">
#reminder-builder {
  #tip-tap-editor {
    margin: 0px !important;
  }
}
</style>
