<template>
  <div id="color-field" class="cursor-pointer">
    <FormFieldLabel
      v-if="label"
      :label="label"
      :is-mandatory="isMandatory"
      :tooltip="tooltip"
    />

    <FormFieldWrapper
      ref="wrapper"
      :is-focused="_isFocused"
      :is-clearable="isClearable"
      :is-readonly="isReadonly"
      :is-disabled="isDisabled"
      :has-error="!!error"
      action-icon="eva-chevron-down"
      @click="toggleColorPicker"
      @clear="handleClear"
    >
      <div
        :tabindex="0"
        class="color"
        @focus="isFocused = true"
        @blur="isFocused = false"
      >
        <div class="row">
          <div class="color-dot" :class="bgColor(value)"></div>
          <div class="font-medium">{{ colorLabel(value) }}</div>
        </div>
      </div>

      <!-- color picker -->

      <q-menu
        v-model="isColorPickerVisible"
        :target="$refs.wrapper"
        fit
        max-width="320px"
        no-refocus
        no-parent-event
        transition-show="scale"
        transition-hide="scale"
      >
        <div id="colors">
          <div
            v-for="color in colors"
            :key="color"
            v-close-popup
            class="color"
            :class="bgColor(color)"
            @click="handleInput(color)"
          >
            <BaseIcon
              v-if="value === color"
              name="eva-checkmark"
              color="white"
            />
          </div>
        </div>
      </q-menu>

      <!-- ... -->
    </FormFieldWrapper>

    <FormFieldError v-if="error" :error="error" />
  </div>
</template>

<script>
import { lowerCase, startCase } from "lodash-es";
import { mapState } from "vuex";

import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldWrapper from "@/components/common/form/field-wrapper/FormFieldWrapper.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";

export default {
  name: "ColorField",

  components: {
    FormFieldLabel,
    FormFieldWrapper,
    FormFieldError,
  },

  props: {
    value: {
      type: String,
      default: "",
    },

    label: {
      type: String,
      default: "",
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      default: "",
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      isFocused: false,
      colors: [
        "primary",
        "secondary",
        "red",
        "pink",
        "purple",
        "deep-purple",
        "indigo",
        "blue",
        "light-blue",
        "teal",
        "green",
        "light-green",
        "lime",
        "amber",
        "orange",
        "deep-orange",
        "brown",
      ],
      isColorPickerVisible: false,
    };
  },

  computed: {
    ...mapState(["darkTheme"]),

    _isFocused() {
      return this.isFocused || this.isColorPickerVisible;
    },

    isClearable() {
      return !!this.value;
    },
  },

  methods: {
    colorLabel(color) {
      return startCase(lowerCase(color));
    },

    toggleColorPicker() {
      this.isColorPickerVisible = !this.isColorPickerVisible;
    },

    bgColor(color) {
      return this.darkTheme ? `bg-${color}-5` : `bg-${color}`;
    },

    handleInput(color) {
      this.isColorPickerVisible = false;
      this.$emit("input", color);
    },

    handleClear() {
      this.$emit("input", "");
    },
  },
};
</script>

<style lang="scss" scoped>
#color-field {
  .color {
    min-height: 46px;
    outline: none;
    padding: 0px 8px;
    display: flex;
    align-items: center;
  }

  .color-dot {
    height: 20px;
    width: 20px;
    border-radius: 100%;
    margin-right: 8px;
  }
}

#colors {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 12px;

  .color {
    height: 36px;
    width: 36px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4px;
    cursor: pointer;

    &:last-child {
      margin-right: 0px;
    }
  }
}
</style>
