<template>
  <div>
    <!-- initiate by -->

    <FormFieldLabel label="action by" is-mandatory />

    <div class="options-wrapper">
      <template>
        <!-- user -->

        <CheckboxField
          :value="value.includes('USER')"
          label="User"
          description="The actor in this block is an user"
          class="q-mb-md"
          @input="updateValue('USER')"
        />

        <Users
          v-if="value.includes('USER')"
          :users="users"
          :user-list="userList"
          class="field q-mb-md"
        />

        <!-- ... -->

        <!-- group -->

        <CheckboxField
          :value="value.includes('GROUP')"
          label="Group"
          description="The actor in this block is a group of users"
          class="q-mb-md"
          @input="updateValue('GROUP')"
        />

        <Groups
          v-if="value.includes('GROUP')"
          :groups="groups"
          :group-list="groupList"
          class="field q-mb-md"
        />

        <!-- ... -->
      </template>
    </div>

    <FormFieldError v-if="error" :error="error" />

    <!-- ... -->
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import Users from "./Users.vue";
import Groups from "./Groups.vue";
export default {
  name: "InitiateBy",

  components: {
    FormFieldLabel,
    CheckboxField,
    FormFieldError,
    Users,
    Groups,
  },

  props: {
    value: {
      type: Array,
      required: true,
    },

    error: {
      type: String,
      default: "",
    },

    userList: {
      type: Array,
      default: () => [],
    },

    groupList: {
      type: Array,
      default: () => [],
    },

    users: {
      type: Array,
      default: () => [],
    },

    groups: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    updateValue(option) {
      const _value = [...this.value];

      if (_value.includes(option)) {
        const optionIdx = _value.indexOf(option);
        _value.splice(optionIdx, 1);
      } else {
        _value.push(option);
      }

      this.$emit("input", _value);
    },
  },
};
</script>

<style lang="scss" scoped>
.options-wrapper {
  border-top: 1px solid var(--divider-color);
  padding-top: 16px;
}
</style>
