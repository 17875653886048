<template>
  <Modal :value="value" width="480px" @input="closeModal">
    <!-- title -->

    <template #title>connect blocks</template>

    <!-- ... -->

    <template #default>
      <div id="workflow-blocks">
        <div
          v-for="block in _blocks"
          :key="block.id"
          class="block"
          @click="connectBlock(block.id)"
        >
          <div class="icon-wrapper" :style="{ backgroundColor: block.color }">
            <BaseIcon :name="block.icon" is-white />
          </div>

          <div class="col">
            <div class="label">{{ getBlockLabel(block) }}</div>
            <div class="description">{{ getBlockType(block) }}</div>
          </div>

          <div class="counter">{{ block.counter }}</div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/common/popup/Modal.vue";

export default {
  name: "ConnectBlocksModal",

  components: { Modal },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    blocks: {
      type: Array,
      default: () => [],
    },

    activeBlockId: {
      type: String,
      default: "",
    },
  },

  computed: {
    _blocks() {
      return this.blocks.filter((block) => block.id !== this.activeBlockId);
    },
  },

  methods: {
    getBlockLabel(block) {
      return block.settings.label;
    },

    getBlockType(block) {
      return block.type;
    },

    closeModal() {
      this.$emit("input", false);
    },

    connectBlock(blockId) {
      this.$emit("connectBlock", blockId);
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
#workflow-blocks {
  .tabs {
    margin: 0px 16px;
    border-bottom: 1px solid var(--divider-color);
  }

  .block {
    display: flex !important;
    align-items: center;
    padding: 12px 16px;
    margin: 0px -16px;
    user-select: none;
    transition: all 0.3s ease;

    &:hover {
      background-color: var(--hover-bg-color);
      cursor: pointer;
    }

    .icon-wrapper {
      height: 36px;
      width: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      margin-right: 16px;
    }

    .label {
      font-weight: 500;
      text-transform: capitalize;
      flex: 1;
    }

    .description,
    .counter {
      @extend .text-sm;
      color: var(--icon-color-inverted);
    }

    .counter {
      margin-right: 12px;
    }
  }
}
</style>
