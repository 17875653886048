<template>
  <div id="workflow-builder-wrapper">
    <ItemBuilder @close="closeBuilder">
      <!-- header -->

      <template #title> Workflow {{ _tab }} </template>

      <template #description>
        {{ _description }}
      </template>

      <template #header>
        <div class="row items-center justify-center">
          <Tabs v-model="tab" :tabs="tabs" is-full-width class="tabs" />
        </div>
      </template>

      <template #actions>
        <BaseActionButton
          icon="eva-question-mark"
          color="secondary"
          class="q-mr-sm"
          @click="showQuickHelp"
        />
      </template>

      <!-- ... -->

      <!-- content -->

      <WorkflowBuilder
        v-show="tab === 'BUILDER'"
        :workflow-id="workflowId"
        :workflow="workflow"
        :is-loading="isLoading"
        @update="updateBlocks"
        @settings="tab = 'SETTINGS'"
        @save="saveWorkflow"
      />

      <WorkflowSettings
        v-if="tab === 'SETTINGS'"
        :workflow="workflow"
        @update="updateSettings"
      />

      <WorkflowPublish
        v-if="tab === 'PUBLISH'"
        :workflow-settings="workflow.settings"
        @update="updateSettings"
      />

      <!-- ... -->

      <!-- ... -->

      <!-- quick help -->

      <QuickHelp v-model="quickHelp" />

      <!-- ... -->
    </ItemBuilder>
  </div>
</template>

<script>
import ItemBuilder from "@/components/common/item-builder/ItemBuilder.vue";
import Tabs from "@/components/common/tabs/Tabs.vue";
import QuickHelp from "@/components/common/QuickHelp.vue";
import { capitalize, lowerCase } from "lodash-es";
import { workflow } from "@/api/factory.js";
import WorkflowBuilder from "./workflow-builder/WorkflowBuilder.vue";
import WorkflowSettings from "./workflow-settings/WorkflowSettings.vue";
import WorkflowPublish from "./workflow-publish/WorkflowPublish.vue";

export default {
  name: "WorkflowBuilderWrapper",

  components: {
    ItemBuilder,
    Tabs,
    QuickHelp,
    WorkflowBuilder,
    WorkflowSettings,
    WorkflowPublish,
  },

  props: {
    workflowId: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      quickHelp: false,
      isLoading: false,
      tab: "BUILDER",
      tabs: [
        {
          id: this.$nano.id(),
          label: "Builder",
          value: "BUILDER",
          description:
            "Create and automate workflows with our intuitive workflow builder",
        },
        {
          id: this.$nano.id(),
          label: "Settings",
          value: "SETTINGS",
          description: "Customize and manage your workflow settings",
        },
        {
          id: this.$nano.id(),
          label: "Publish",
          value: "PUBLISH",
          description: "Publish and deploy your workflow in action",
        },
      ],
      workflow: {
        blocks: [],
        rules: [],
        settings: {
          general: {
            name: "Workflow - " + this.$day.newDateTime(),
            description: "",
            initiateUsing: {
              type: "DOCUMENT_FORM",
              repositoryId: 0,
              formId: 0,
            },
            ocr: {
              required: false,
              credit: 0,
            },
          },
          publish: {
            publishOption: "DRAFT",
            publishSchedule: "",
            unpublishSchedule: "",
          },
        },
        modifiedBlockIds: [],
        blockStatus: 0,
        mlPredictions: [],
        hasSLASettings: 1,
        initiateUserDomain: [],
      },
    };
  },

  computed: {
    _tab() {
      return capitalize(lowerCase(this.tab));
    },

    _description() {
      let currentTab = this.tabs.filter((item) => item.value == this.tab);
      return currentTab[0].description;
    },
  },

  watch: {
    workflowId: {
      immediate: true,
      handler() {
        if (this.workflowId) {
          this.mode = "EDIT";
          this.getWorkflow();
        }
      },
    },
  },

  methods: {
    showQuickHelp() {
      this.quickHelp = true;
    },

    closeBuilder() {
      this.$router.back();
    },

    // updateBlocks(blocks) {
    //   this.workflow.blocks = blocks;
    // },

    updateBlocks(newBlockData) {
      // Find the index of the block with the matching ID
      const blockIndex = this.workflow.blocks.findIndex(
        (block) => block.id === newBlockData.id
      );

      // If the block is found, update it
      if (blockIndex !== -1) {
        // Update the specific block with new values
        this.workflow.blocks[blockIndex] = {
          ...this.workflow.blocks[blockIndex], // Preserve existing values
          ...newBlockData, // Update with new data
        };

        // Trigger reactivity by assigning a new array reference
        this.workflow.blocks = [...this.workflow.blocks];
        console.log(this.workflow, "flows");
      } else {
        console.error("Block not found with ID:", newBlockData.id);
      }
    },

    updateSettings(settings) {
      this.workflow.settings = settings;
      this.tab = "BUILDER";
    },

    saveWorkflow() {
      let ruleBlock = this.workflow.blocks.filter(
        (block) => block.type === "RULES"
      );
      if (ruleBlock.length) {
        let ruleNames = [];
        ruleBlock[0].settings.ruleConditions.forEach((rule) => {
          if (ruleNames.indexOf(rule.name) === -1) ruleNames.push(rule.name);
        });
        if (ruleNames.indexOf("Exception") === -1) ruleNames.push("Exception");
        this.workflow.mlPredictions = ruleNames;
      }
      this.workflow.hasSLASettings = 1;
      if (
        this.workflow.settings.general.slaRules &&
        this.workflow.settings.general.slaRules.length
      ) {
        this.workflow.hasSLASettings = 2;
      }
      if (this.workflow.blocks.length) {
        let slaNode = this.workflow.blocks.filter(
          (block) => block.settings.slaRules?.length > 0
        );
        if (slaNode.length) {
          if (this.workflow.hasSLASettings === 1) {
            this.workflow.hasSLASettings = 3;
          } else {
            this.workflow.hasSLASettings = 4;
          }
        }
      }

      let startBlock = this.workflow.blocks.find(
        (block) => block.type === "START"
      );
      if (startBlock) {
        if (startBlock.settings.users.indexOf("0") > -1) {
          this.workflow.initiateRights = false;
        }

        if (
          startBlock.settings.initiateBy.indexOf("DOMAIN_USER") > -1 &&
          startBlock.settings.userDomains.length
        ) {
          this.workflow.initiateUserDomain = startBlock.settings.userDomains;
        }
      }

      this.workflow.blocks.forEach((block) => {
        if (block.settings.notifications) {
          if (
            typeof block.settings.notifications.acknowledgement.teamsNotify ===
            "string"
          ) {
            block.settings.notifications.acknowledgement.teamsNotify = false;
          }
          if (
            typeof block.settings.notifications.inboxNotification
              .teamsNotify === "string"
          ) {
            block.settings.notifications.inboxNotification.teamsNotify = false;
          }
          if (
            typeof block.settings.notifications.acknowledgement.slackNotify ===
            "string"
          ) {
            block.settings.notifications.acknowledgement.slackNotify = false;
          }
          if (
            typeof block.settings.notifications.inboxNotification
              .slackNotify === "string"
          ) {
            block.settings.notifications.inboxNotification.slackNotify = false;
          }
        }
      });

      // const workflowJson = JSON.stringify(this.workflow);
      // console.log(workflowJson);
      if (this.workflowId) {
        this.updateWorkflow();
      } else {
        this.createWorkflow();
      }
    },

    /* api functions */

    async createWorkflow() {
      this.isLoading = true;

      const payload = this.workflow;

      const { error } = await workflow.createWorkflow(payload);

      if (error) {
        if (error !== "error creating workflow") {
          this.$alert.warning(error);
        } else {
          this.$alert.error(error);
        }
        this.isLoading = false;
        return;
      }

      this.$alert.success("New workflow created");
      this.closeBuilder();
    },

    async updateWorkflow() {
      this.workflow.blocks.forEach((block) => {
        if (block.settings.slaRules && block.settings.slaRules.length) {
          block.settings.slaRules.forEach((field) => {
            if (typeof field.settingsJson === "object") {
              field.settingsJson = JSON.stringify(field.settingsJson);
            }
          });
        }
      });
      console.log(this.workflow);

      this.isLoading = true;

      const payload = this.workflow;

      const { error } = await workflow.updateWorkflow(this.workflowId, payload);

      if (error) {
        if (error !== "error updating workflow") {
          this.$alert.warning(error);
        } else {
          this.$alert.error(error);
        }
        this.isLoading = false;
        return;
      }

      this.$alert.success("workflow updated");
      this.closeBuilder();
    },

    async getWorkflow() {
      this.$store.commit("showLoadingBar");

      const { error, payload } = await workflow.getWorkflow(this.workflowId);

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.workflow = JSON.parse(payload.flowJson);
      this.workflow.settings.general.initiateUsing.repositoryId =
        payload.repositoryId;
      this.workflow.modifiedBlockIds = [];
      this.workflow.blockStatus = 0;
      this.workflow.mlPredictions = [];
    },

    /* ... */
  },
};
</script>

<style lang="scss" scoped>
#workflow-builder-wrapper {
  height: 100vh;

  .tabs {
    width: 240px;
    border-bottom: 1px solid var(--divider-color);
  }
}
</style>
