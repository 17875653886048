<template>
  <FormSection
    :icon="isActive ? 'eva-paper-plane' : 'eva-paper-plane-outline'"
    :is-active="isActive"
    v-on="$listeners"
  >
    <template #title>Connection</template>
    <template #description> Connect with services </template>

    <template v-for="connect in connections">
      <div :key="connect.id">
        <SelectField
          v-model="connect.name"
          is-mandatory
          label="Services"
          :options="connectionList"
          class="q-mb-lg"
        />

        <template v-if="connect.name === 'jira'">
          <MultiSelectField
            v-model="connect.actions"
            is-mandatory
            label="Actions"
            :options="jiraActionList"
            class="q-mb-lg"
          />

          <SelectField
            v-model="connect.jiraLinkField"
            is-mandatory
            label="JIRA Link Field"
            :options="jiraActionList"
            class="q-mb-lg"
          />

          <SelectField
            v-model="connect.jiraCommentField"
            is-mandatory
            label="JIRA Comment Field"
            :options="jiraActionList"
            class="q-mb-lg"
          />
        </template>
      </div>
    </template>
  </FormSection>
</template>

<script>
import FormSection from "@/components/common/item-builder/FormSection.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
// import { form } from "@/api/factory.js";

export default {
  name: "ConnectionSettings",

  components: {
    FormSection,
    SelectField,
    MultiSelectField,
  },

  props: {
    value: {
      type: Array,
      default: () => [],
    },
    formId: {
      type: Number,
      default: 0,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      connectionList: [
        {
          id: this.$nano.id(),
          label: "JIRA",
          value: "jira",
        },
      ],
      jiraActionList: [
        {
          id: this.$nano.id(),
          label: "Create Issue",
          value: "Create_Issue",
        },
        {
          id: this.$nano.id(),
          label: "Assign Issue",
          value: "Assign_Issue",
        },
        {
          id: this.$nano.id(),
          label: "Add Comments",
          value: "Add_Comments",
        },
        {
          id: this.$nano.id(),
          label: "Read Comments",
          value: "Read_Comments",
        },
        {
          id: this.$nano.id(),
          label: "Add Attachments",
          value: "Add_Attachments",
        },
      ],
    };
  },

  watch: {
    connections: {
      deep: true,
      handler() {
        this.$emit("input", this.connections);
      },
    },
  },

  created() {
    this.connections = { ...this.value };
  },

  methods: {},
};
</script>

<style lang="scss" scoped></style>
