<template>
  <div id="preView">
    <BaseScrollbar
      :height="'calc(100vh - 130px)'"
      :width="'calc(100vw -900px)'"
      style="overflow-x: auto; overflow-y: auto"
    >
      <BaseCloseButton
        class="q-mt-md"
        style="margin-left: 90%"
        @click="$emit('preview')"
      />
      <div style="padding-left: 10px">
        <!-- Adjust width as needed -->
        <svg
          ref="network"
          width="100%"
          height="100%"
          style="overflow: visible; padding: 10px"
        ></svg>
      </div>
    </BaseScrollbar>
  </div>
</template>
<script>
import * as d3 from "d3";
import { user } from "@/api/factory.js";

export default {
  name: "D3Network",

  props: {
    blocks: {
      type: Array,
      required: true,
    },
    connections: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      debounceTimer: null,
      userList: [],
    };
  },

  watch: {
    blocks: {
      deep: true,
      handler() {
        this.debounceCreateNetwork();
      },
    },
    connections: {
      deep: true,
      handler() {
        this.debounceCreateNetwork();
      },
    },
  },

  mounted() {
    this.createNetwork();
  },

  beforeDestroy() {
    // Clear the debounce timer if the component is destroyed
    clearTimeout(this.debounceTimer);
  },

  created() {
    this.getUsers();
  },

  methods: {
    // Debounced method to create network
    debounceCreateNetwork() {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.createNetwork();
      }, 300); // Adjust delay as needed
    },

    createNetwork() {
      const svg = d3.select(this.$refs.network);
      svg.selectAll("*").remove(); // Clear previous drawings

      const blockHeight = 90;
      const blockSpacing = 30;

      // Initialize variables for viewBox calculation
      let minX = Infinity;
      let maxX = -Infinity;
      let minY = Infinity;
      let maxY = -Infinity;

      const orderedBlocks = [];
      // const getLightColor = (color) => d3.color(color).brighter(1.5).toString();
      // Add all START blocks first
      const startBlocks = this.blocks.filter((block) =>
        block.type.startsWith("START")
      );
      orderedBlocks.push(...startBlocks);

      // Add other blocks in connection order
      this.connections.forEach((connection) => {
        const button = connection.proceedAction; // Get the proceedAction value
        connection.buttonValue = button; // Save it as buttonValue in the connection
        const fromBlock = this.blocks.find(
          (b) => b.id === connection.fromBlockId
        );
        const toBlock = this.blocks.find((b) => b.id === connection.toBlockId);

        // Ensure we add the fromBlock and toBlock if they're not already included
        if (
          fromBlock &&
          !orderedBlocks.includes(fromBlock) &&
          !startBlocks.includes(fromBlock)
        ) {
          orderedBlocks.push(fromBlock);
        }
        if (
          toBlock &&
          !orderedBlocks.includes(toBlock) &&
          !startBlocks.includes(toBlock)
        ) {
          orderedBlocks.push(toBlock);
        }
      });

      // Add all END blocks that are part of a connection
      // const endBlocks = this.blocks.filter((block) =>
      //     block.type.startsWith("END") &&
      //     this.connections.some(connection => connection.toBlockId === block.id || connection.fromBlockId === block.id)
      // );
      // orderedBlocks.push(...endBlocks);

      // Calculate bounding box for viewBox
      orderedBlocks.forEach((block, index) => {
        const yPosition = index * (blockHeight + blockSpacing);
        minY = Math.min(minY, yPosition);
        maxY = Math.max(maxY, yPosition + blockHeight);
        minX = Math.min(minX, 50); // Assuming x starts at 50
        maxX = Math.max(maxX, 50 + (block.width || 150)); // Width of the block
      });

      // Dynamically set the viewBox
      const padding = 10; // Add some padding around the content
      const viewBox = `${minX - padding} ${minY - padding} ${
        maxX - minX + padding * 2
      } ${maxY - minY + padding * 2}`;
      svg.attr("viewBox", viewBox);

      // Set the height dynamically based on the number of blocks
      const totalHeight = orderedBlocks.length * (blockHeight + blockSpacing);
      svg.attr("height", totalHeight);

      const colorPalette = [
        "#FFCCCB",
        "#FFE4B5",
        "#E6E6FA",
        "#FFB6C1",
        "#B0E0E6",
        "#F0E68C",
        "#FFDEAD",
        "#D3D3D3",
        "#FFCCC6",
        "#FFE4B6",
        "#E6E6F3",
        "#FFB6C4",
        "#B0E0EA",
        "#F0E68D",
        "#FFDEAF",
        "#D3D3D7",
      ];

      const getDynamicColor = (index) =>
        colorPalette[index % colorPalette.length];

      // Define arrow marker for paths
      svg
        .append("defs")
        .append("marker")
        .attr("id", "arrow")
        .attr("viewBox", "0 0 10 10")
        .attr("refX", 3)
        .attr("refY", 5)
        .attr("markerWidth", 2)
        .attr("markerHeight", 2)
        .attr("orient", "auto")
        .append("path")
        .attr("d", "M0,0 L0,10 L10,5 Z")
        .attr("fill", "#333");

      // Draw connections first
      this.connections.forEach((connection, index) => {
        const fromBlock = this.blocks.find(
          (b) => b.id === connection.fromBlockId
        );
        const toBlock = this.blocks.find((b) => b.id === connection.toBlockId);
        const delay = index * 1600; // Sequential delay for animation
        const pathData = this.connectionPath(
          connection,
          orderedBlocks,
          blockHeight,
          blockSpacing
        );
        const connectionName =
          connection.buttonValue || "Assign value for the Action"; // Default if buttonValue is not set
        console.log(connectionName, "connectionName"); // Verify the button value
        const fromLabel = fromBlock?.settings?.label || "No Label";
        const toLabel = toBlock?.settings?.label || "No Label";

        // Draw the connection line first
        const path = svg
          .append("path")
          .attr("d", pathData)
          .attr("fill", "none")
          .attr("stroke", getDynamicColor(index))
          .attr("stroke-width", 2)
          .attr("stroke-linecap", "round")
          .attr("stroke-dasharray", function () {
            return this.getTotalLength();
          })
          .attr("stroke-dashoffset", function () {
            return this.getTotalLength();
          })
          .style("cursor", "pointer")
          .on("mouseover", (event) => {
            d3.select("body")
              .append("div")
              .attr("class", "tooltip")
              .style("position", "absolute")
              .style("background", "white")
              .style("color", "black")
              .style("border", "1px solid var(--divider-color)")
              .style("padding", "5px")
              .style("border-radius", "5px")
              .style("font-size", "12px")
              .style("box-shadow", "0px 8px 8px rgba(2.5, 2.3, 2.4, 2.2)") // Adds a box shadow
              .style("transition", "opacity 0.5s ease")
              .style("z-index", "1000")

              .html(
                `<div class="q-ml-md q-mt-md"><strong>From:</strong> ${fromLabel}</div>
           <div  class="q-ml-md"><strong>To:</strong> ${toLabel}</div>
           <div  class="q-ml-md q-mb-md"><strong>Action:</strong> ${connectionName}</div>`
              )
              .style("left", `${event.pageX + 10}px`)
              .style("top", `${event.pageY}px`);
          })
          .on("mouseout", () => {
            d3.select(".tooltip").remove();
          })
          .transition()
          .duration(800)
          .delay(delay)
          .attr("stroke-dashoffset", 0); // Draw the line

        // Calculate the midpoint of the path for the icon position
        const pathLength = path.node().getTotalLength();
        const midpoint = path.node().getPointAtLength(pathLength / 2);

        // Add an <i> icon element at the midpoint of the path with delay
        svg
          .append("foreignObject")
          .attr("x", midpoint.x - 8) // Adjust position to center the icon
          .attr("y", midpoint.y - 10)
          .attr("width", 20) // Width of the icon container
          .attr("height", 20) // Height of the icon container
          .style("opacity", 0) // Start hidden
          .transition()
          .duration(300) // Duration for icon fade-in
          .delay(delay + 800) // Delay for path animation + extra delay for icon
          .style("opacity", 1) // Fade in the icon
          .on("start", function () {
            d3.select(this)
              .append("xhtml:i") // Using XHTML namespace for <i> in SVG
              .attr(
                "class",
                connectionName === "Assign value for the Action"
                  ? "mdi mdi-information"
                  : "mdi mdi-check-circle"
              ) // Replace with desired icon class
              .style("font-size", "16px")
              .style("cursor", "pointer")
              .style(
                "color",
                connectionName === "Assign value for the Action"
                  ? "red"
                  : "green"
              )
              .style("border-radius", "50px")
              .on("mouseover", (event) => {
                d3.select("body")
                  .append("div")
                  .attr("class", "tooltip")
                  .style("position", "absolute")
                  .style("background", "white")
                  .style("color", "black")
                  .style("border", "1px solid var(--divider-color)")
                  .style("padding", "5px")
                  .style("border-radius", "5px")
                  .style("font-size", "12px")
                  .style("box-shadow", "0px 8px 8px rgba(2.5, 2.3, 2.4, 2.2)") // Adds a box shadow
                  .style("transition", "opacity 0.5s ease")
                  .style("z-index", "1000")
                  .html(
                    `<div class="q-ml-md q-mt-md"><strong>From:</strong> ${fromLabel}</div>
               <div class="q-ml-md"><strong>To:</strong> ${toLabel}</div>
               <div class="q-ml-md q-mb-md"><strong>Action:</strong> ${connectionName}</div>`
                  )
                  .style("left", `${event.pageX + 10}px`)
                  .style("top", `${event.pageY}px`);
              })
              .on("mouseout", () => {
                d3.select(".tooltip").remove();
              });
          });
      });
      const classifications = {
        General: [
          "users",
          "label",
          "groups",
          "dynamicUser",
          "manager",
          "toInitiator",
          "fullApprovalAction",
          "generatePDF",
          "coordinator",
          "actedActivity",
          "internalForward",
          "partialApprove",
          "documentRequired",
        ],
        Connection: ["ipAddress", "port"],
        notification: ["email", "sms"],
        "security-access": ["role", "permissions"],
        Rules: ["MLConditions"],
      };

      const getEmptyKeys = (settings) => {
        const emptyKeysCount = {};

        // Define the specific keys to skip if any of them are present
        const skipKeysIfAnyPresent = [
          "users",
          "toInitiator",
          "groups",
          "dynamicUser",
          "coordinator",
          "manager",
        ];

        // Check if any key in skipKeysIfAnyPresent has a non-empty value
        const shouldSkipGeneralKeys = skipKeysIfAnyPresent.some(
          (key) =>
            settings[key] && // Value exists for this key
            !(
              (
                (Array.isArray(settings[key]) && settings[key].length === 0) || // non-empty array
                (typeof settings[key] === "string" &&
                  settings[key].trim() === "") || // non-empty string
                (typeof settings[key] === "object" &&
                  settings[key] !== null &&
                  !Array.isArray(settings[key]) &&
                  Object.keys(settings[key]).length === 0)
              ) // non-empty object
            )
        );

        // Initialize the count for each classification
        for (const classification in classifications) {
          emptyKeysCount[classification] = 0;
        }

        // Iterate through each classification
        for (const classification in classifications) {
          classifications[classification].forEach((key) => {
            const value = settings[key];

            // Skip the check for "General" classification if any key in skipKeysIfAnyPresent has a value
            if (classification === "General" && shouldSkipGeneralKeys) {
              return; // Skip this key
            }

            // Check if the value is empty
            if (
              (Array.isArray(value) && value.length === 0) || // empty array
              (typeof value === "string" && value.trim() === "") || // empty string
              (typeof value === "object" &&
                value !== null &&
                !Array.isArray(value) &&
                Object.keys(value).length === 0) // empty object
            ) {
              emptyKeysCount[classification] += 1; // Increment the count for empty keys
            }
          });
        }

        // Filter out classifications that have no empty settings
        const result = Object.entries(emptyKeysCount)
          .filter(([, count]) => count > 0) // Only include classifications with empty settings
          .map(
            ([key, count]) =>
              `${key}: ${count} setting${count > 1 ? "s" : ""} missing`
          ); // Format result
        console.log(result, "result");
        return result; // Returns an array of strings for the tooltip
      };

      // Draw each block and reveal them sequentially
      orderedBlocks.forEach((block, index) => {
        const delay = index * 1600;
        const yPosition = index * (blockHeight + blockSpacing);
        const emptyKeys = getEmptyKeys(block.settings);

        //settings notifications

        // Block Container
        svg
          .append("rect")
          .attr("class", "box")
          .attr("x", 50)
          .attr("y", yPosition)
          .attr("width", block.width || 150)
          .attr("height", blockHeight - 20)
          .attr("fill", "none")
          .attr("rx", 5)
          .attr("opacity", 0)
          .on("click", () => {
            const settings = block.settings; // Assuming block.settings contains the settings for the current block
            const emptyKeysCount = getEmptyKeys(settings);
            console.log(emptyKeysCount, "emptyKeysCount");
            // Check if there are any missing settings
            const hasMissingSettings = Object.values(emptyKeysCount).some(
              (count) => count > 0
            );

            if (hasMissingSettings) {
              this.$emit("blockDetails", block); // Emit block details to the parent component
            }
          })
          .transition()
          .duration(800)
          .delay(delay)
          .attr("opacity", 1);
        // Add block content layout with foreignObject for flexibility
        // Add block content layout with foreignObject for flexibility
        // Add block content layout with foreignObject for flexibility
        svg
          .append("foreignObject")
          .attr("x", 55)
          .attr("y", yPosition + 10)
          .attr("width", block.width - 20 || 150)
          .attr("height", blockHeight)
          .html(
            `
    <div style="display: flex; flex-direction: column; height: 100%; width: 100%; border: 1px solid var(--divider-color); border-radius: 5px; overflow: hidden;">
      <div style="display: flex; align-items: center; padding: 10px;  background-color: ${this.lightenHexColor(
        block.color,
        80
      )}; color: ${block.color}; font-weight: bold;">
        <i class="mdi ${
          block.icon
        }" style="font-size: 20px; margin-right: 8px; cursor: pointer;" id="icon-${
              block.id
            }"></i>
        <span id="label" class="ellipsis" >${
          block.settings?.label || "No Label"
        }</span>
        
        ${
          emptyKeys.length > 0
            ? `
          <i class="mdi mdi-eye warning-icon" id="warningIcon-${block.id}" style="
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #c1c1c1;
  width: 20px;
  height: 20px;
  font-style: normal;
  font-weight: bold;
  cursor: pointer;
  margin-left: auto;
">
  
</i>
        `
            : ""
        }
      </div>
      <div style="flex: 1; display: flex;  background-color: #fbfafa; color: gray;" class="row">
        ${
          block.settings?.users?.length > 0
            ? `
          <div class="col-auto q-mt-sm q-pa-sm" style="position: relative;" >
            <i class="mdi mdi-account userIcon" style="font-size: 30px; cursor: pointer; color: #c1c1c1;" id="userIcon-${block.id}"></i>
            <span style="position: absolute; right: 0px; background-color:#c1c1c1; color: white; border-radius: 50%;top-10px, padding: 2px 5px; font-size: 12px; font-weight: bold;width:15px;height:18px;text-align:center">
              ${block.settings.users.length}
            </span>
          </div>
        `
            : ""
        }
         ${
           block.settings?.manager
             ? `
          <div class="col-auto q-mt-sm q-pa-sm" style="position: relative;">
            <i class="mdi mdi-school userIcon" style="font-size: 30px; cursor: pointer; color:#c1c1c1;" id="userIcon-${block.id}"/></i>
            
          </div>
        `
             : ""
         }
         ${
           block.settings?.toRequester
             ? `
          <div class="col-auto q-mt-sm q-pa-sm" style="position: relative;">
            <i class="mdi mdi-account-convert-outline userIcon" style="font-size: 30px; cursor: pointer; color: #c1c1c1;" id="userIcon-${block.id}"></i>
           
          </div>
        `
             : ""
         }
         ${
           block.settings?.groups?.length > 0
             ? `
          <div class="col-autoq-mt-sm q-pa-sm" style="position: relative;">
            <i class="mdi mdi-account-group userIcon" style="font-size: 30px; cursor: pointer; color:#c1c1c1;" id="userIcon-${block.id}"></i>
           
          </div>
        `
             : ""
         }
         ${
           block.settings?.dynamicUser?.length > 0
             ? `
          <div class="col-auto q-mt-sm q-pa-sm" style="position: relative;">
            <i class="mdi mdi-account-convert-outline userIcon" style="font-size: 30px; cursor: pointer; color: #c1c1c1;" id="userIcon-${block.id}"></i>
           
          </div>
        `
             : ""
         }
      </div>
    </div>
  `
          )
          .attr("opacity", 0)
          .on("mouseover", function () {
            d3.select(this).select("div").style("border", "2px solid #333");
          })
          .on("mouseout", function () {
            d3.select(this).select("div").style("border", "1px solid #ddd");
          })
          .transition()
          .duration(800)
          .delay(delay)
          .attr("opacity", 1);

        const warningIconSelector = `#warningIcon-${block.id}`;
        if (emptyKeys.length > 0) {
          d3.select(warningIconSelector)
            .on("mouseover", (event) => {
              // Remove any existing tooltip
              d3.select(".tooltip").remove();

              const emptyKeysList = emptyKeys
                .map((key) => `<li>${key}</li>`)
                .join("");

              // Append tooltip
              d3.select("body")
                .append("div")
                .attr("class", "tooltip")
                .style("position", "absolute")
                .style("background", "white")
                .style("color", "black")
                .style("border", "1px solid #ddd")
                .style("padding", "5px")
                .style("border-radius", "5px")
                .style("font-size", "12px")
                .style("box-shadow", "0px 8px 8px rgba(2.5, 2.3, 2.4, 2.2)") // Adds a box shadow
                .style("transition", "opacity 0.5s ease")
                .style("z-index", "1000")
                .html(
                  `
          <div style="text-align:center;font-weight: bold;text-decoration: underline;">Pending Settings</div>
          <ul style="padding-left: 15px; margin: 0;">${emptyKeysList}</ul>
        `
                )
                .style("left", `${event.pageX + 10}px`)
                .style("top", `${event.pageY}px`);
            })
            .on("mouseout", () => {
              d3.select(".tooltip").remove();
            });
        }

        // Tooltip for the label
        d3.select(`#label-${block.id}`)
          .on("mouseover", (event) => {
            // Remove any existing tooltip
            d3.select(".tooltip").remove();

            let matchingUsers = this.userList
              .filter((user) => block.settings.users.includes(user.value))
              .map((user) => `<li>${user.label}</li>`)
              .join("");
            if (!matchingUsers) {
              matchingUsers = "<li>ANY</li>";
            }

            // Append tooltip
            d3.select("body")
              .append("div")
              .attr("class", "tooltip")
              .style("position", "absolute")
              .style("background", "white")
              .style("color", "black")
              .style("border", "1px solid #ddd")
              .style("padding", "5px")
              .style("border-radius", "5px")
              .style("font-size", "12px")
              .style("box-shadow", "0px 8px 8px rgba(2.5, 2.3, 2.4, 2.2)") // Adds a box shadow
              .style("transition", "opacity 0.5s ease")
              .style("z-index", "1000")
              .html(
                `
        <div style="text-align:center;font-weight: bold;text-decoration: underline;">User List</div>
        <ul style="padding-left: 15px; margin: 0;">${matchingUsers}</ul>
      `
              )
              .style("left", `${event.pageX + 10}px`)
              .style("top", `${event.pageY}px`);
          })
          .on("mouseout", () => {
            d3.select(".tooltip").remove();
          });

        // Tooltip for the icon
        d3.select(`#icon-${block.id}`)
          .on("mouseover", (event) => {
            // Remove any existing tooltip
            d3.select(".tooltip").remove();

            // Create tooltip content
            const tooltipContent = `<div style="text-align:center;font-weight: bold;">${block.type}</div>`;

            // Append tooltip
            d3.select("body")
              .append("div")
              .attr("class", "tooltip")
              .style("position", "absolute")
              .style("background", "white")
              .style("color", "black")
              .style("border", "1px solid #ddd")
              .style("padding", "5px")
              .style("border-radius", "5px")
              .style("font-size", "12px")
              .html(tooltipContent)
              .style("left", `${event.pageX + 10}px`)
              .style("box-shadow", "0px 8px 8px rgba(2.5, 2.3, 2.4, 2.2)") // Adds a box shadow
              .style("transition", "opacity 0.5s ease")
              .style("z-index", "1000")
              .style("top", `${event.pageY}px`);
          })
          .on("mouseout", () => {
            d3.select(".tooltip").remove();
          });

        // Tooltip for the user icon
        const userIconSelector = `#userIcon-${block.id}`;
        if (block.settings?.users?.length > 0) {
          d3.select(userIconSelector)
            .on("mouseover", (event) => {
              // Remove any existing tooltip
              d3.select(".tooltip").remove();

              let matchingUsers = this.userList
                .filter((user) => block.settings.users.includes(user.value))
                .map((user) => `<li>${user.label}</li>`)
                .join("");
              if (!matchingUsers && block.settings?.users?.length > 0) {
                matchingUsers = "<li>ANY</li>";
              }

              // Append tooltip
              d3.select("body")
                .append("div")
                .attr("class", "tooltip")
                .style("position", "absolute")
                .style("background", "white")
                .style("color", "black")
                .style("border", "1px solid #ddd")
                .style("padding", "5px")
                .style("border-radius", "5px")
                .style("font-size", "12px")
                .style("box-shadow", "0px 8px 8px rgba(2.5, 2.3, 2.4, 2.2)") // Adds a box shadow
                .style("transition", "opacity 0.5s ease")
                .style("z-index", "1000")
                .html(
                  `
          <div style="text-align:center;font-weight: bold;text-decoration: underline;">User List</div>
          <ul style="padding-left: 15px; margin: 0;">${matchingUsers}</ul>
        `
                )
                .style("left", `${event.pageX + 10}px`)
                .style("top", `${event.pageY}px`);
            })
            .on("mouseout", () => {
              d3.select(".tooltip").remove();
            });
        }
      });
    },

    lightenHexColor(hex, percent) {
      // Ensure the hex is a valid 6 or 3 digit hex code
      hex = hex.replace(/^#/, "");
      if (hex.length === 3) {
        hex = hex
          .split("")
          .map((c) => c + c)
          .join("");
      }

      // Convert hex to RGB
      let r = parseInt(hex.slice(0, 2), 16);
      let g = parseInt(hex.slice(2, 4), 16);
      let b = parseInt(hex.slice(4, 6), 16);

      // Lighten each color component
      r = Math.min(255, Math.floor(r + (255 - r) * (percent / 100)));
      g = Math.min(255, Math.floor(g + (255 - g) * (percent / 100)));
      b = Math.min(255, Math.floor(b + (255 - b) * (percent / 100)));

      // Convert back to hex
      return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
    },
    connectionPath(connection, sortedBlocks, blockHeight, blockSpacing) {
      const fromBlock = sortedBlocks.find(
        (b) => b.id === connection.fromBlockId
      );
      const toBlock = sortedBlocks.find((b) => b.id === connection.toBlockId);

      if (!fromBlock || !toBlock) return "";

      const startIndex = sortedBlocks.indexOf(fromBlock);
      const endIndex = sortedBlocks.indexOf(toBlock);

      const startX = 50 + (fromBlock.width || 150) / 2; // Centered on the rectangle
      const startY =
        startIndex * (blockHeight + blockSpacing) + blockHeight - 70; // Bottom of the fromBlock

      const endX = 50 + (toBlock.width || 150) / 2; // Centered on the rectangle
      const endY = endIndex * (blockHeight + blockSpacing) + 15; // Top of the toBlock

      // Check if blocks are adjacent
      const isAdjacent = Math.abs(startIndex - endIndex) === 1;

      if (isAdjacent) {
        // Direct connection for adjacent blocks
        return `M${startX},${startY + 80} L${endX},${endY}`;
      } else {
        const startY =
          startIndex * (blockHeight + blockSpacing) +
          blockHeight +
          (startIndex % 2 === 0 ? -10 : -40);
        const endY = endIndex * (blockHeight + blockSpacing) + 25; // Top of the toBlock
        const horizontalShift = 90 + Math.abs(startIndex - endIndex) * 10;

        const adjustedStartX =
          startX + (startIndex % 2 === 0 ? horizontalShift : -horizontalShift);

        // Create a unique path avoiding direct line overlaps
        return `M${adjustedStartX},${startY} L${adjustedStartX},${
          startY - 20
        } L${adjustedStartX + (startIndex % 2 === 0 ? -100 : 100)},${
          startY - 20
        } L${adjustedStartX},${
          startY - 20
        } L${adjustedStartX},${endY} L${endX},${endY}`;
      }
    },

    async getUsers() {
      this.userList = [];
      const { error, payload } = await user.getUserList();

      if (error) {
        this.$alert.error("Error fetching users");
        return;
      }

      if (payload) {
        payload.map((user) => {
          this.userList.push({
            id: this.$nano.id(),
            label: user.value || user.loginName,
            value: String(user.id),
          });
        });
      }
      console.log(this.userList, "list");
    },
  },
};
</script>

<style scoped>
.tooltip {
  position: absolute;
  background: #f9f9f9;
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
  pointer-events: none;
}
.block-content {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
#preView {
  background-color: white;
  border-top: 1px solid var(--border-color);
}
</style>
<style>
.ellipsis:hover {
  max-width: none !important;
  overflow: visible;
  white-space: pre;
}
</style>
