<template>
  <div>
    <!-- initiate using -->

    <FormFieldLabel label="initiate using" />

    <div class="options-wrapper">
      <!-- document -->

      <RadioField
        :value="initiateUsing.type === 'DOCUMENT'"
        label="Document"
        description="Process document workflows"
        class="q-mb-md"
        @input="initiateUsing.type = 'DOCUMENT'"
      />

      <!-- ... -->

      <!-- form -->

      <RadioField
        :value="initiateUsing.type === 'FORM'"
        label="Form"
        description="Use an input form to start"
        class="q-mb-md"
        @input="initiateUsing.type = 'FORM'"
      />

      <!-- ... -->

      <!-- both -->

      <RadioField
        :value="initiateUsing.type === 'DOCUMENT_FORM'"
        label="Document & Form"
        description="Use document and form to start"
        @input="initiateUsing.type = 'DOCUMENT_FORM'"
      />

      <!-- ... -->
    </div>

    <!-- ... -->

    <!-- folder -->

    <ValidationProvider
      v-if="showRepository"
      v-slot="{ errors }"
      name="folder"
      :rules="repositoryValidation"
    >
      <SelectField
        v-model="initiateUsing.repositoryId"
        :is-mandatory="repositoryValidation.required"
        label="folder"
        :options="repositorylist"
        :error="errors[0]"
        class="q-mt-lg"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- form -->

    <ValidationProvider
      v-if="showForm"
      v-slot="{ errors }"
      name="form"
      :rules="{ required: true }"
    >
      <SelectField
        v-model="initiateUsing.formId"
        is-mandatory
        label="form"
        :options="formlist"
        :error="errors[0]"
        class="q-mt-lg"
      />
    </ValidationProvider>

    <!-- ... -->

    <div
      v-if="initiateUsing.type === 'FORM' && !initiateUsing.repositoryId"
      class="q-mt-md"
    >
      <CheckboxField
        v-model="isCreditRequired"
        label="Data Extraction from Attachments"
        description="Credits requried to extract the data from the attachments"
        class="q-mb-md"
      />

      <div v-if="isCreditRequired">
        <FormFieldLabel
          label="Credits"
          tooltip="Free Credits: The available credits offer you a certain amount of advanced OCR processing without any additional cost. Each credit allows you to perform advanced indexing on a document within this folder. As you assign credits, you'll be able to utilize the advanced features of our OCR engine for more accurate document indexing."
        />

        <BaseSeparator />

        <div class="text-red q-mt-md">
          Available Credits: {{ availableCredits }}
        </div>

        <div class="row">
          <ValidationProvider
            v-slot="{ errors }"
            name="Credits"
            :rules="{ min_value: 0, max_value: overallCredits }"
          >
            <CounterField
              v-model="creditValue"
              :is-mandatory="true"
              label="Assign credits for this folder"
              placeholder="Credits of Advance indexing"
              :error="errors[0]"
              class="col-5 q-mt-md"
              @input="updateCreditLimit"
            />
          </ValidationProvider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import RadioField from "@/components/common/form/radio-field/RadioField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import { repository, form } from "@/api/factory.js";
import CounterField from "@/components/common/form/counter-field/CounterField.vue";

import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";

export default {
  name: "InitiateUsing",

  components: {
    ValidationProvider,
    FormFieldLabel,
    RadioField,
    SelectField,
    CounterField,
    CheckboxField,
  },

  props: {
    value: {
      type: Object,
      required: true,
    },

    ocrCredit: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      initiateUsing: {
        type: "",
        repositoryId: 0,
        formId: 0,
      },
      repositorylist: [],
      formlist: [],
      credits: 0,
      overallCredits: 0,
      availableCredits: 0,
      creditValue: 0,
      creditLimit: 0,
      constantCredit: 0,
      isCreditRequired: false,
    };
  },

  computed: {
    showRepository() {
      return (
        this.initiateUsing.type === "DOCUMENT" ||
        this.initiateUsing.type === "DOCUMENT_FORM" ||
        this.initiateUsing.type === "FORM"
      );
    },

    showForm() {
      return (
        this.initiateUsing.type === "FORM" ||
        this.initiateUsing.type === "DOCUMENT_FORM"
      );
    },

    repositoryValidation() {
      var rules = { required: true };
      if (
        this.initiateUsing.type === "DOCUMENT" ||
        this.initiateUsing.type === "DOCUMENT_FORM"
      ) {
        rules.required = true;
      } else if (this.initiateUsing.type === "FORM") {
        rules.required = false;
      }

      return rules;
    },
  },

  watch: {
    initiateUsing: {
      deep: true,
      handler() {
        this.$emit("input", this.initiateUsing);
        if (this.initiateUsing.type === "FORM") {
          this.getOcrCredit();
        }

        if (this.initiateUsing.repositoryId) {
          this.$emit("credit", this.creditValue, this.isCreditRequired);
        }
      },
    },

    creditValue: {
      deep: true,
      immediate: true,
      handler() {
        this.updateAvailableCredits();
      },
    },

    isCreditRequired: {
      deep: true,
      immediate: true,
      handler() {
        this.$emit("credit", 0, this.isCreditRequired);
        this.$emit("creditRequired", this.isCreditRequired);
      },
    },
  },

  created() {
    this.initiateUsing = { ...this.value };
    this.getRepositoryList();
    this.getFormList();
    this.isCreditRequired = this.ocrCredit ? this.ocrCredit.required : false;
    this.creditValue = this.ocrCredit ? this.ocrCredit.credit : 0;
    this.updateAvailableCredits();
  },

  methods: {
    /* api functions */

    async getRepositoryList() {
      const { error, payload } = await repository.getRepositoryList();

      if (error) {
        this.$alert.error("Error fetching repository list");
        return;
      }

      this.repositorylist =
        payload &&
        payload.map((repository) => ({
          id: this.$nano.id(),
          label: repository.value,
          value: repository.id,
        }));
    },

    async getFormList() {
      this.$store.commit("showLoadingBar");
      const { error, payload } = await form.getForms({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "DESC" },
        groupBy: "",
        filterBy: [
          {
            filters: [
              {
                criteria: "type",
                condition: "IS_EQUALS_TO",
                value: "WORKFLOW",
                dataType: "",
              },
              {
                criteria: "publishOption",
                condition: "IS_EQUALS_TO",
                value: "PUBLISHED",
                dataType: "",
              },
            ],
            groupCondition: "",
          },
        ],
        itemsPerPage: 500,
        currentPage: 1,
        hasSecurity: false,
      });

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.formlist = [];
      const { data } = payload;
      if (data.length) {
        this.formlist = data[0].value.map((form) => ({
          id: this.$nano.id(),
          label: form.name,
          value: form.id,
        }));
      }
    },

    async getOcrCredit() {
      const id = 0;

      const { error, payload } = await repository.getOcrCredit(id);

      if (error) {
        this.$alert.error(error);
        return;
      }
      const numericPayload = parseFloat(payload);

      this.availableCredits = numericPayload;
      this.credits = numericPayload;
      this.overallCredits = numericPayload;
      const updatedCredits = this.creditValue;
      const existingCredits = this.credits;
      const newCredits = existingCredits - updatedCredits;
      this.availableCredits = Math.max(newCredits, 0);
    },

    updateAvailableCredits() {
      const updatedCredits = this.creditValue;
      const existingCredits = this.credits;
      const newCredits = existingCredits - updatedCredits;

      this.availableCredits = Math.max(newCredits, 0);
      // const availableCredit = this.credits;
      // const newAvailableCredits = availableCredit - newCredits;
    },

    updateCreditLimit() {
      if (!this.initiateUsing.repositoryId) {
        if (this.credits >= this.creditValue) {
          this.$emit("credit", this.creditValue, this.isCreditRequired);
        }
      } else {
        this.$emit("credit", 0, this.isCreditRequired);
      }
    },

    /* ... */
  },
};
</script>

<style lang="scss" scoped>
.options-wrapper {
  border-top: 1px solid var(--divider-color);
  padding-top: 16px;
}
</style>
