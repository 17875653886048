import { render, staticRenderFns } from "./EmailInitiator.vue?vue&type=template&id=8385e5ca&scoped=true&"
import script from "./EmailInitiator.vue?vue&type=script&lang=js&"
export * from "./EmailInitiator.vue?vue&type=script&lang=js&"
import style0 from "./EmailInitiator.vue?vue&type=style&index=0&id=8385e5ca&prod&lang=scss&scoped=true&"
import style1 from "./EmailInitiator.vue?vue&type=style&index=1&id=8385e5ca&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8385e5ca",
  null
  
)

export default component.exports