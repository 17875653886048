<template>
  <div id="fields-header">
    <div
      class="row items-center"
      :class="[position === 'TOP' ? 'header-top' : 'header-bottom']"
    >
      <div class="label col">Card Name</div>
      <div class="label" style="width: 100px">Display Portal</div>
      <div class="label" style="width: 100px">Card Color</div>
      <div class="label" style="width: 140px">Nodes</div>

      <BaseActionButton
        v-tooltip:secondary.left="'add cards'"
        is-flat
        color="secondary"
        icon="eva-plus"
        no-border
        @click="$emit('add')"
      />

      <BaseActionButton
        v-tooltip:red.right="'clear all cards'"
        is-flat
        color="red"
        no-border
        icon="eva-trash-2-outline"
        @click="$emit('clear')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "CardsHeader",

  props: {
    position: {
      type: String,
      default: "TOP",
    },
  },
};
</script>

<style lang="scss" scoped>
#fields-header {
  .header-top {
    border-bottom: 1px solid var(--divider-color);
    padding-bottom: 8px;
    margin-bottom: 8px;
  }

  .header-bottom {
    border-top: 1px solid var(--divider-color);
    padding-top: 8px;
    margin-top: 8px;
  }

  .label {
    @extend .text-sm;
    color: var(--icon-color-inverted);
  }
}
</style>
