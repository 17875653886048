<template>
  <div id="workflow-header" @mouseleave="handleFocusOut" @blur="handleFocusOut">
    <!-- workflow info -->

    <div class="row items-center">
      <!-- settings -->

      <BaseActionButton
        v-tooltip.top="'edit workflow settings'"
        icon="eva-edit"
        is-flat
        color="primary"
        class="q-mr-md"
        @click="$emit('settings')"
      />

      <!-- ... -->

      <div>
        <div class="title-2" @click="openNameInput = true">
          <template v-if="!openNameInput">{{ workflowName }}</template>

          <input
            v-if="openNameInput"
            v-model="workflowSettings.general.name"
            type="text"
            autofocus
            class="title"
            @mouseleave="openNameInput = false"
          />
        </div>

        <div class="description" @click="openDecsInput = true">
          <template v-if="!openDecsInput">{{ workflowDescription }}</template>
          <input
            v-if="openDecsInput"
            v-model="workflowSettings.general.description"
            type="text"
            autofocus
            class="description"
            @mouseleave="openDecsInput = false"
          />
        </div>
      </div>
    </div>

    <!-- ... -->

    <q-space />

    <BaseButton
      :label="isPreview ? 'Close Preview' : 'Preview'"
      color="secondary"
      :icon="isPreview ? 'eva-eye-off-outline' : 'eva-eye-outline'"
      class="q-mr-sm"
      @click="$emit('preview')"
    />

    <BaseButton
      label="save"
      :is-loading="isLoading"
      icon="eva-save-outline"
      @click="$emit('save')"
    />
  </div>
</template>

<script>
export default {
  name: "WorkflowHeader",

  props: {
    workflowSettings: {
      type: Object,
      required: true,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    isPreview: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      openNameInput: false,
      openDecsInput: false,
      showNameModal: false,
    };
  },

  computed: {
    workflowName() {
      return this.workflowSettings.general.name || "Workflow name";
    },

    workflowDescription() {
      return (
        this.workflowSettings.general.description || "workflow description"
      );
    },
  },

  methods: {
    handleFocusOut() {
      this.openNameInput = false;
      this.openDecsInput = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#workflow-header {
  height: 56px;
  display: flex;
  align-items: center;
  padding: 0px 16px;
  background-color: var(--component-bg-color);

  .description {
    @extend .text-sm;
    color: var(--icon-color);
  }

  .title-2:hover,
  .description:hover {
    cursor: auto;
  }

  .title {
    color: var(--title-2-color);
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600 !important;
    width: 900px !important;
  }

  input:focus {
    transition: opacity 90ms ease-in-out 0s;
  }
}
</style>
