<template>
  <div id="tertiary-header">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "TertiaryHeader",
};
</script>

<style lang="scss" scoped>
#tertiary-header {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #{rgba($color: $gray-6, $alpha: 0.1)};
  padding: 12px 16px;
  margin-left: -16px;
  margin-right: -16px;

  .label {
    @extend .text-sm;
    font-weight: 500;
    color: var(--icon-color);
  }

  .action {
    cursor: pointer;
  }
}
</style>
