<template>
  <div id="fields-builder">
    <!-- label -->

    <FormFieldLabel v-if="false" label="cards" is-mandatory />

    <!-- ... -->

    <!-- header -->

    <KanbanHeader @add="showFieldSheet" @clear="clearAllFields" />

    <!-- ... -->

    <KanbanCards
      :kanban-cards="cards"
      @edit="editField"
      @delete="deleteField"
    />

    <!-- ... -->

    <!-- bottom header -->

    <KanbanHeader
      v-if="value.length"
      position="BOTTOM"
      @add="showFieldSheet"
      @clear="clearAllFields"
    />

    <!-- ... -->

    <!-- error -->

    <FormFieldError v-if="error" :error="error" />

    <!-- ... -->

    <!-- field details-->

    <KanbanDetails
      v-model="isKanbanDetailsVisible"
      :field="field"
      :fields="value"
      :workflow="workflow"
      @add="addField"
      @save="saveField"
    />

    <!-- ... -->
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import KanbanHeader from "./components/KanbanHeader.vue";
import KanbanCards from "./components/KanbanCards.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import KanbanDetails from "./components/kanban-details/KanbanDetails.vue";
import { lowerCase } from "lodash-es";

export default {
  name: "KanbanBuilder",

  components: {
    FormFieldLabel,
    KanbanHeader,
    KanbanDetails,
    KanbanCards,
    FormFieldError,
  },

  props: {
    value: {
      type: Array,
      required: true,
    },

    error: {
      type: String,
      default: "",
    },

    workflow: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isKanbanDetailsVisible: false,
      field: null,
    };
  },

  computed: {
    cards: {
      cache: false,
      get() {
        return this.value;
      },
    },
  },

  watch: {
    isKanbanDetailsVisible() {
      if (!this.isKanbanDetailsVisible) {
        this.field = null;
      }
    },
  },

  methods: {
    showFieldSheet() {
      this.isKanbanDetailsVisible = true;
    },

    addField(field) {
      const isAlreadyExists = this.value.find(
        (_field) => lowerCase(_field.name) === lowerCase(field.name)
      );

      if (isAlreadyExists) {
        this.$alert.error("Field with the given name already exists");
        return;
      }

      this.$emit("input", [...this.value, field]);
    },

    clearAllFields() {
      this.$emit("input", []);
    },

    editField(fieldId) {
      this.field = this.value.find((field) => field._id === fieldId);
      this.showFieldSheet();
    },

    saveField(field) {
      const fieldIdx = this.value.findIndex(
        (_field) => _field._id === field._id
      );

      const fields = this.value;
      fields[fieldIdx] = field;

      let fields1 = JSON.stringify(fields);

      this.$emit("input", JSON.parse(fields1));
    },

    deleteField(fieldId) {
      const fieldIdx = this.value.findIndex((field) => field._id === fieldId);

      const fields = this.value;
      fields.splice(fieldIdx, 1);

      this.$emit("input", fields);
    },
  },
};
</script>

<style lang="scss" scoped></style>
