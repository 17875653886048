import { fabric } from "fabric";
import { mapState } from "vuex";

export default {
  data() {
    return {
      hLanesCount: 20,
      vLanesCount: 20,
      laneHeaderHeight: 36,
      laneWidth: 215,
      laneHeight: 125,
      laneHeaderTextColorLight: "#643094",
      laneHeaderTextColorDark: "#a357ba",
    };
  },

  computed: {
    ...mapState(["darkTheme"]),
  },

  methods: {
    createVLane(counter) {
      // header

      const header = new fabric.Rect({
        top: 0,
        left: 0,
        width: this.laneWidth,
        height: this.laneHeaderHeight,
        fill: "transparent",
      });

      // name

      const name = new fabric.Text("V-Lane " + counter, {
        fontFamily: "Outfit, sans-serif",
        fontSize: 14,
        fontWeight: "500",
        fill: this.darkTheme
          ? this.laneHeaderTextColorDark
          : this.laneHeaderTextColorLight,
      });

      name.left = (this.laneWidth - name.width) / 2;
      name.top = (header.height - name.height) / 2;

      // body

      const body = new fabric.Rect({
        top: this.laneHeaderHeight,
        left: 0,
        width: this.laneWidth,
        height: this.laneHeight * this.vLanesCount,
        fill: "transparent",
      });

      // border

      const x1 = body.width;
      const y1 = body.top;
      const x2 = body.width;
      const y2 = body.height + this.laneHeaderHeight;
      const border = new fabric.Line([x1, y1, x2, y2], {
        stroke: "#64748b26",
        strokeWidth: this.activeView === "PLAIN" ? 0 : 1,
      });

      return new fabric.Group([header, name, body, border], {
        selectable: false,
        hoverCursor: "default",
      });
    },

    createHLane(counter) {
      // header

      const header = new fabric.Rect({
        top: 0,
        left: 0,
        width: this.laneHeaderHeight,
        height: this.laneHeight,
        fill: "transparent",
      });

      // name

      const name = new fabric.Text("H-Lane " + counter, {
        fontFamily: "Outfit, sans-serif",
        fontSize: 14,
        fontWeight: "500",
        fill: this.darkTheme
          ? this.laneHeaderTextColorDark
          : this.laneHeaderTextColorLight,
        angle: -90,
      });

      name.top = (header.height + name.width) / 2;
      name.left = (header.width - name.height) / 2;

      // body

      const body = new fabric.Rect({
        top: 0,
        left: this.laneHeaderHeight,
        width: this.vLanesCount * this.laneWidth + header.width,
        height: this.laneHeight,
        fill: "transparent",
      });

      // border

      const x1 = body.left;
      const y1 = body.height;
      const x2 = body.width;
      const y2 = body.height;

      const border = new fabric.Line([x1, y1, x2, y2], {
        stroke: "#64748b26",
        strokeWidth: this.activeView === "PLAIN" ? 0 : 1,
      });

      return new fabric.Group([header, name, body, border], {
        selectable: false,
        hoverCursor: "default",
        top: this.laneHeaderHeight,
      });
    },

    createVLanes() {
      const vLanes = [];

      // left boundry line

      const x1 = this.laneHeaderHeight;
      const y1 = x1;
      const x2 = x1;
      const y2 = this.laneHeight * this.vLanesCount + this.laneHeaderHeight;

      const leftBoundry = new fabric.Line([x1, y1, x2, y2], {
        stroke: "#64748b26",
        strokeWidth: 1,
      });
      vLanes.push(leftBoundry);

      // other lines

      for (let i = 0; i < this.vLanesCount; i++) {
        const vLane = this.createVLane(i + 1);
        vLane.left = i * this.laneWidth + this.laneHeaderHeight;
        vLanes.push(vLane);
      }

      return vLanes;
    },

    createHLanes() {
      const hLanes = [];

      const x1 = this.laneHeaderHeight;
      const y1 = x1;
      const x2 = this.laneWidth * this.vLanesCount + this.laneHeaderHeight;
      const y2 = x1;

      const border = new fabric.Line([x1, y1, x2, y2], {
        stroke: "#64748b26",
        strokeWidth: 1,
      });
      hLanes.push(border);

      for (let i = 0; i < this.hLanesCount; i++) {
        const hLane = this.createHLane(i + 1);
        hLane.top = i * this.laneHeight + this.laneHeaderHeight;
        hLanes.push(hLane);
      }

      return hLanes;
    },
  },
};
