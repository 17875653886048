import { fabric } from "fabric";

export default {
  data() {
    return {
      ARROW_HEIGHT: 4,
      ARROW_WIDTH: 2,
      HEIGHT_FACTOR: 8,
      MIN_HEIGHT_DIFFERENCE: 100,
      MIN_WIDTH_DIFFERENCE: 100,
    };
  },

  methods: {
    createConnection(fromBlock, toBlock, connectionId) {
      const { left: x1, top: y1, width: w1, height: h1 } = fromBlock;
      const { left: x2, top: y2, width: w2, height: h2 } = toBlock;

      const widthDifference = Math.abs(x1 - x2);
      const heightDifference = Math.abs(y1 - y2);

      let lineDirection = "";
      let arrowDirection = "";

      if (
        widthDifference <= this.MIN_WIDTH_DIFFERENCE &&
        heightDifference <= this.MIN_HEIGHT_DIFFERENCE
      ) {
        if (y1 <= y2) {
          lineDirection = "X_AXIS_U_BEND_DOWN";
          arrowDirection = "DOWN";
        }

        if (y1 > y2) {
          lineDirection = "X_AXIS_U_BEND_UP";
          arrowDirection = "UP";
        }
      } else if (heightDifference <= this.MIN_HEIGHT_DIFFERENCE) {
        if (x1 <= x2) {
          lineDirection = "X_AXIS_Z_BEND_RIGHT";
          arrowDirection = "RIGHT";
        }

        if (x1 > x2) {
          lineDirection = "X_AXIS_Z_BEND_LEFT";
          arrowDirection = "LEFT";
        }
      } else {
        if (y1 >= y2) {
          lineDirection = "X_AXIS_Z_BEND_UP";
          arrowDirection = "UP";
        }

        if (y1 < y2) {
          lineDirection = "X_AXIS_Z_BEND_DOWN";
          arrowDirection = "DOWN";
        }
      }

      return new fabric.Group(
        [
          this.getLine(x1, y1, h1, w1, x2, y2, h2, w2, lineDirection),
          this.getArrow(x2, y2, h2, w2, arrowDirection),
        ],
        {
          id: connectionId || this.$nano.id(),
          type: "connection",
          fromBlockId: fromBlock.id,
          toBlockId: toBlock.id,
          evented: false,
        }
      );
    },

    getLine(x1, y1, h1, w1, x2, y2, h2, w2, lineDirection) {
      const lineOptions = {
        stroke: "#64748b",
        strokeWidth: 1,
        fill: "transparent",
        strokeLineJoin: "round",
        hoverCursor: "pointer",
      };

      // X_AXIS_U_BEND_DOWN
      if (lineDirection === "X_AXIS_U_BEND_DOWN") {
        const _x1 = x1 + w1 / 2;
        const _y1 = y1;

        const _x2 = _x1;
        const _y2 = _y1 - this.HEIGHT_FACTOR;

        const _x3 = x2 + w2 / 2;
        const _y3 = _y2;

        const _x4 = _x3;
        const _y4 = y2;

        return new fabric.Polyline(
          [
            { x: _x1, y: _y1 },
            { x: _x2, y: _y2 },
            { x: _x3, y: _y3 },
            { x: _x4, y: _y4 },
          ],
          lineOptions
        );
      }

      // X_AXIS_U_BEND_UP
      if (lineDirection === "X_AXIS_U_BEND_UP") {
        const _x1 = x1 + w1 / 2;
        const _y1 = y1 + h1;

        const _x2 = _x1;
        const _y2 = _y1 + this.HEIGHT_FACTOR;

        const _x3 = x2 + w2 / 2;
        const _y3 = _y2;

        const _x4 = _x3;
        const _y4 = y2 + h2;

        return new fabric.Polyline(
          [
            { x: _x1, y: _y1 },
            { x: _x2, y: _y2 },
            { x: _x3, y: _y3 },
            { x: _x4, y: _y4 },
          ],
          lineOptions
        );
      }

      // X_AXIS_Z_BEND_RIGHT
      if (lineDirection === "X_AXIS_Z_BEND_RIGHT") {
        const midPointX = (x1 + w1 + x2) / 2;

        const _x1 = x1 + w1;
        const _y1 = y1 + h1 / 2;

        const _x2 = midPointX;
        const _y2 = _y1;

        const _x3 = midPointX;
        const _y3 = y2 + h1 / 2;

        const _x4 = x2;
        const _y4 = _y3;

        return new fabric.Polyline(
          [
            { x: _x1, y: _y1 },
            { x: _x2, y: _y2 },
            { x: _x3, y: _y3 },
            { x: _x4, y: _y4 },
          ],
          lineOptions
        );
      }

      // X_AXIS_Z_BEND_LEFT
      if (lineDirection === "X_AXIS_Z_BEND_LEFT") {
        const midPointX = (x1 + w1 + x2) / 2;

        const _x1 = x1;
        const _y1 = y1 + h1 / 2;

        const _x2 = midPointX;
        const _y2 = _y1;

        const _x3 = midPointX;
        const _y3 = y2 + h1 / 2;

        const _x4 = x2 + w2;
        const _y4 = _y3;

        return new fabric.Polyline(
          [
            { x: _x1, y: _y1 },
            { x: _x2, y: _y2 },
            { x: _x3, y: _y3 },
            { x: _x4, y: _y4 },
          ],
          lineOptions
        );
      }

      // X_AXIS_Z_BEND_DOWN
      if (lineDirection === "X_AXIS_Z_BEND_DOWN") {
        const _x1 = x1 + w1 / 2;
        const _y1 = y1 + h1;

        const _x2 = _x1;
        const _y2 = y2 - this.HEIGHT_FACTOR;

        const _x3 = x2 + w2 / 2;
        const _y3 = _y2;

        const _x4 = _x3;
        const _y4 = y2;

        return new fabric.Polyline(
          [
            { x: _x1, y: _y1 },
            { x: _x2, y: _y2 },
            { x: _x3, y: _y3 },
            { x: _x4, y: _y4 },
          ],
          lineOptions
        );
      }

      // X_AXIS_Z_BEND_UP
      if (lineDirection === "X_AXIS_Z_BEND_UP") {
        const _x1 = x1 + w1 / 2;
        const _y1 = y1;

        const _x2 = _x1;
        const _y2 = _y1 - this.HEIGHT_FACTOR;

        const _x3 = x2 + w2 / 2;
        const _y3 = _y2;

        const _x4 = _x3;
        const _y4 = y2 + h2;

        return new fabric.Polyline(
          [
            { x: _x1, y: _y1 },
            { x: _x2, y: _y2 },
            { x: _x3, y: _y3 },
            { x: _x4, y: _y4 },
          ],
          lineOptions
        );
      }
    },

    getArrow(x2, y2, h2, w2, arrowDirection) {
      let _x1,
        _y1,
        _x2,
        _y2,
        _x3,
        _y3,
        _x4,
        _y4 = 0;

      // down arrow

      if (arrowDirection === "DOWN") {
        _x1 = x2 + w2 / 2;
        _y1 = y2;

        _x2 = _x1 + this.ARROW_WIDTH;
        _y2 = _y1 - this.ARROW_HEIGHT;

        _x3 = _x1 - this.ARROW_WIDTH;
        _y3 = _y2;

        _x4 = _x1;
        _y4 = _y1;
      }

      // up arrow

      if (arrowDirection === "UP") {
        _x1 = x2 + w2 / 2;
        _y1 = y2 + h2;

        _x2 = _x1 + this.ARROW_WIDTH;
        _y2 = _y1 + this.ARROW_HEIGHT;

        _x3 = _x1 - this.ARROW_WIDTH;
        _y3 = _y2;

        _x4 = _x1;
        _y4 = _y1;
      }

      // right arrow

      if (arrowDirection === "RIGHT") {
        _x1 = x2;
        _y1 = y2 + h2 / 2;

        _x2 = _x1 - this.ARROW_HEIGHT;
        _y2 = _y1 + this.ARROW_WIDTH;

        _x3 = _x2;
        _y3 = _y1 - this.ARROW_WIDTH;

        _x4 = _x1;
        _y4 = _y1;
      }

      // left arrow

      if (arrowDirection === "LEFT") {
        _x1 = x2 + w2;
        _y1 = y2 + h2 / 2;

        _x2 = _x1 + this.ARROW_HEIGHT;
        _y2 = _y1 - this.ARROW_WIDTH;

        _x3 = _x2;
        _y3 = _y1 + this.ARROW_WIDTH;

        _x4 = _x1;
        _y4 = _y1;
      }

      return new fabric.Polyline(
        [
          { x: _x1, y: _y1 },
          { x: _x2, y: _y2 },
          { x: _x3, y: _y3 },
          { x: _x4, y: _y4 },
        ],
        {
          fill: "#64748b",
          stroke: "#64748b",
          strokeWidth: 1,
          strokeLineJoin: "round",
        }
      );
    },
  },
};
