<template>
  <Sheet :value="value" width="540px" has-footer @input="closeSheet">
    <!-- title -->

    <template #title>{{ field ? "Edit" : "Add" }} Card</template>

    <!-- ... -->

    <!-- field details -->

    <template #default>
      <div class="q-pa-md">
        <ValidationObserver ref="form">
          <!-- name -->

          <ValidationProvider
            v-slot="{ errors }"
            name="card name"
            :rules="{ required: true, checkDuplicate: { target: labelError } }"
          >
            <TextField
              v-model="name"
              label="card name"
              is-mandatory
              :error="errors[0]"
              class="q-mb-lg"
            />
          </ValidationProvider>

          <!-- ... -->

          <!-- Display on Portal -->

          <CheckboxField
            v-model="isDisplayPortal"
            label="Marking Cards Name for Display on Portal"
            class="q-mb-md"
          />

          <!-- ... -->

          <!-- color -->

          <ColorField v-model="color" label="color" class="q-mb-lg" />

          <!-- ... -->

          <!-- worflow nodes -->
          <ValidationProvider
            v-slot="{ errors }"
            name="workflow nodes"
            :rules="{ required: true }"
          >
            <MultiSelectField
              v-model="workflowNode"
              label="workflow nodes"
              is-mandatory
              :is-clearable="false"
              :options="workflowNodes"
              class="q-mb-lg"
              :error="errors[0]"
            />
          </ValidationProvider>

          <!-- ... -->
        </ValidationObserver>
      </div>
    </template>

    <!-- ... -->

    <!-- footer -->

    <template #footer>
      <BaseButton
        is-flat
        label="cancel"
        class="q-mr-sm"
        @click="closeSheet(false)"
      />

      <BaseButton
        :label="field ? 'save' : 'add'"
        @click="field ? saveKanban() : addKanban()"
      />
    </template>

    <!-- ... -->
  </Sheet>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";

import Sheet from "@/components/common/popup/Sheet.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import ColorField from "@/components/common/form/color-field/ColorField.vue";

export default {
  name: "KanbanDetails",

  components: {
    Sheet,
    ValidationObserver,
    ValidationProvider,
    TextField,
    CheckboxField,
    MultiSelectField,
    ColorField,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    field: {
      type: Object,
      default: null,
    },

    fields: {
      type: Array,
      default: () => [],
    },

    workflow: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      name: "",
      isDisplayPortal: false,
      color: "",
      workflowNode: [],
      fieldsColumns: [],
      workflowNodes: [],
      labelError: "",
    };
  },

  watch: {
    field: {
      immediate: true,
      handler() {
        if (this.field) {
          this.name = this.field.name;
          this.isDisplayPortal = this.field.isDisplayPortal;
          this.color = this.field.color;
          this.workflowNode = this.field.workflowNode;
        } else {
          this.reset();
        }
      },
    },

    workflow: {
      deep: true,
      immediate: true,
      handler() {
        // console.log(this.workflow.blocks);

        if (this.workflow) {
          this.workflowNodes = [];
          if (this.workflow.blocks.length) {
            // console.log(this.workflow.blocks);
            this.workflow.blocks.forEach((node) => {
              if (
                !this.workflowNodes.find(
                  (row) => row.value === node.settings.label
                )
              ) {
                this.workflowNodes.push({
                  id: this.$nano.id(),
                  label: node.settings.label,
                  value: node.settings.label,
                });
              }
            });
          }
        }
      },
    },

    name() {
      if (this.name) {
        this.labelError = "";
        let labelFound = false;
        this.fields.forEach((row) => {
          if (this.field) {
            if (
              row._id !== this.field._id &&
              row.name.toLowerCase() === this.name.toLowerCase()
            ) {
              labelFound = true;
              return;
            }
          } else {
            if (row.name.toLowerCase() === this.name.toLowerCase()) {
              labelFound = true;
              return;
            }
          }
        });
        if (labelFound) {
          this.labelError = "Specified name already assigned";
        }
      }
    },

    value: {
      handler() {
        if (this.value) {
          let nodes = [];
          this.fields.forEach((field) => {
            if (field.workflowNode.length) {
              nodes.push(...field.workflowNode);
            }
          });
          this.workflowNodes.forEach((node) => {
            node.readonly = false;
          });
          if (nodes.length) {
            nodes.forEach((node) => {
              let hasNode = this.workflowNodes.find(
                (item) => item.label === node
              );

              if (hasNode) {
                hasNode.readonly = true;
              }
            });
          }
        }
      },
    },
  },

  methods: {
    reset() {
      this.name = "";
      this.isDisplayPortal = false;
      this.color = "";
      this.workflowNode = [];
    },

    closeSheet() {
      this.$emit("input", false);
      this.reset();
    },

    async addKanban() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        return;
      }

      this.$emit("add", {
        _id: this.$nano.id(),
        name: this.name,
        workflowNode: this.workflowNode,
        isDisplayPortal: this.isDisplayPortal,
        color: this.color,
      });

      this.closeSheet();
    },

    async saveKanban() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        return;
      }

      this.$emit("save", {
        _id: this.field._id,
        name: this.name,
        workflowNode: this.workflowNode,
        isDisplayPortal: this.isDisplayPortal,
        color: this.color,
      });

      this.closeSheet();
    },
  },
};
</script>

<style lang="scss" scoped></style>
