import { cloneDeep, startCase } from "lodash-es";

export default {
  methods: {
    handleBlockClick(e) {
      this.activeBlockId = e.target.id;
      this.highLightBlock();

      const targetType = e.subTargets[0]?.type;
      if (!targetType) return;

      if (targetType === "addBlockBtn") {
        this.isAddBlocksModalVisible = true;
      }

      if (targetType === "connectBlockBtn") {
        this.isConnectBlocksModalVisible = true;
      }

      if (targetType === "deleteBlockBtn") {
        this.deleteBlock();
      }

      if (targetType === "duplicateBlockBtn") {
        this.duplicateBlock();
      }

      if (targetType === "blockSettingsBtn") {
        this.isBlockSettingsVisible = true;
      }
    },

    highLightBlock() {
      if (!this.activeBlockId) return;
      this.deselectAll();

      // find the block
      const blockObj = this.canvas._objects.find(
        (_object) => _object.id === this.activeBlockId
      );

      // highlight the block
      blockObj._objects[0].strokeWidth = 1;
      blockObj.dirty = true;

      // highlight associated connections
      const connections = this.connections
        .filter((connection) => connection.fromBlockId === this.activeBlockId)
        .map((connection) => connection.id);

      const connectionObjs = [];

      this.canvas._objects.forEach((_object) => {
        if (connections.includes(_object.id)) {
          connectionObjs.push(_object);
          _object.dirty = true;
          _object._objects[0].stroke = blockObj.color;
          _object._objects[1].stroke = blockObj.color;
          _object._objects[1].fill = blockObj.color;
        }
      });

      this.canvas.renderAll();
      connectionObjs.forEach((_object) => this.canvas.bringToFront(_object));
    },

    deselectAll() {
      this.canvas._objects.forEach((_object) => {
        if (_object.type === "block") {
          _object._objects[0].strokeWidth = 0;
          _object.dirty = true;
        }

        if (_object.type === "connection") {
          _object._objects[0].stroke = "#64748b";
          _object._objects[1].stroke = "#64748b";
          _object._objects[1].fill = "#64748b";
          _object.dirty = true;
        }
      });
      this.canvas.renderAll();
    },

    getBlockLabel(blockType) {
      const blockTypeCount = this.blocks.filter(
        (block) => block.type === blockType
      ).length;
      return `${startCase(blockType)} ${blockTypeCount + 1}`;
    },

    addBlock(blockType, left, top, blockId, blockLabel) {
      const activeBlock = this.blocks.find(
        (block) => block.id === this.activeBlockId
      );

      let _left = left || activeBlock.left + 220;
      let _top = top || activeBlock.top + 40;

      let checkExists = false;
      let incTop = 120;

      do {
        let existBlock = this.blocks.find(
          (block) => block.left === _left && block.top === _top
        );
        if (existBlock) {
          _top += incTop;
          checkExists = true;
        } else {
          checkExists = false;
        }
      } while (checkExists);

      let label = "";
      if (blockLabel) {
        label = blockLabel;
      } else {
        label = this.getBlockLabel(blockType);
      }

      const block = this.createBlock(blockType, label, blockId);
      block.left = _left;
      block.top = _top;

      this.canvas.add(block);
      if (!blockId) {
        this.blocks.push({
          id: block.id,
          left: _left,
          top: _top,
          width: block.width,
          height: block.height,
          color: block.color,
          icon: block.icon,
          type: blockType,
          settings: {
            label: label,
            initiateMode: "MANUAL",
            initiateBy: ["USER"],
            users: [],
            groups: [],
          },
        });

        if (this.activeBlockId) {
          this.connectBlock(block.id);
        }
      }
    },

    connectBlock(blockId) {
      // check if a connection already exists
      const isConnectionExists = this.connections.some(
        (connection) =>
          connection.fromBlockId === this.activeBlockId &&
          connection.toBlockId === blockId
      );
      if (isConnectionExists) return;

      // get fromBlock and toBlock
      const fromBlock = this.blocks.find(
        (block) => block.id === this.activeBlockId
      );
      const toBlock = this.blocks.find((block) => block.id === blockId);

      // create a new connection
      const connection = this.createConnection(fromBlock, toBlock);

      // add connection to the canvas
      this.canvas.add(connection);

      // send it behind the blocks
      const connectionObj = this.canvas._objects.find(
        (object) => object.id === connection.id
      );
      this.canvas.sendBackwards(connectionObj);

      // add connection to the connection list
      this.connections.push({
        id: connection.id,
        left: connection.left,
        top: connection.top,
        fromBlockId: connection.fromBlockId,
        toBlockId: connection.toBlockId,
      });

      this.activeBlockId = "";
    },

    updateConnections(blockId) {
      // get all the connections connecting the block
      const outGoingConnections = this.connections.filter(
        (connection) => connection.fromBlockId === blockId
      );
      const inComingConnections = this.connections.filter(
        (connection) => connection.toBlockId === blockId
      );
      const filteredConnections = [
        ...outGoingConnections,
        ...inComingConnections,
      ];

      // recreate the connection with updated block positions
      filteredConnections.forEach((connection) => {
        const fromBlock = this.blocks.find(
          (block) => block.id === connection.fromBlockId
        );
        const toBlock = this.blocks.find(
          (block) => block.id === connection.toBlockId
        );

        // create a new connection
        const updatedConnection = this.createConnection(fromBlock, toBlock);
        updatedConnection.id = connection.id;

        // replace the old connection with the new connection
        const connectionObjectIdx = this.canvas._objects.findIndex(
          (_object) => _object.id === connection.id
        );
        this.canvas._objects[connectionObjectIdx] = updatedConnection;
      });

      // render the connection
      this.canvas.requestRenderAll();
    },

    deleteBlock() {
      if (!this.activeBlockId) return;

      // find the block
      const blockObj = this.canvas._objects.find(
        (_object) => _object.id === this.activeBlockId
      );

      // remove the block from canvas
      this.canvas.remove(blockObj);

      // remove the block from block list
      this.blocks = this.blocks.filter(
        (block) => block.id !== this.activeBlockId
      );

      // delete associated connections
      const connections = this.connections.filter(
        (connection) =>
          connection.fromBlockId === this.activeBlockId ||
          connection.toBlockId === this.activeBlockId
      );

      connections.forEach((connection) => this.deleteConnection(connection.id));

      this.activeBlockId = "";
    },

    duplicateBlock() {
      if (!this.activeBlockId) return;

      const block = cloneDeep(
        this.blocks.find((block) => block.id === this.activeBlockId)
      );
      if (!block) return;

      block.left += 80;
      block.top += 80;

      const blockLabel = this.getBlockLabel(block.type);

      const clonedBlock = this.createBlock(block.type, blockLabel);
      clonedBlock.left = block.left;
      clonedBlock.top = block.top;
      this.canvas.add(clonedBlock);

      block.id = clonedBlock.id;
      this.blocks.push(block);

      this.activeBlockId = "";
    },

    deleteConnection(connectionId) {
      const connectionIdx = this.connections.findIndex(
        (connection) => connection.id === connectionId
      );
      this.connections.splice(connectionIdx, 1);

      // find the connection object
      const connectionObj = this.canvas._objects.find(
        (_object) => _object.id === connectionId
      );

      // remove the connection from the canvas
      this.canvas.remove(connectionObj);
    },
  },
};
