<template>
  <div id="condition-builder">
    <!-- secondary header -->

    <SecondaryHeader v-model="groupLogic" :items="groupLogics">
      <template #before>If</template>
      <template #after>of the following conditions are met</template>
    </SecondaryHeader>

    <!-- ... -->

    <!-- tertiary header -->

    <TertiaryHeader class="row q-mb-md">
      <div class="label col">Field</div>
      <div class="label col">Logic</div>
      <div class="label col">Value</div>

      <div class="action" @click="addCondition">
        <BaseIcon v-tooltip.top="'add row'" name="eva-plus" color="secondary" />
      </div>
    </TertiaryHeader>

    <!-- ... -->

    <!-- conditions -->

    <div
      v-for="(condition, idx) in conditionsClone"
      :key="condition.id"
      class="condition"
    >
      <!-- name -->

      <SelectField
        v-model="condition.name"
        is-mandatory
        :options="_fields"
        class="col"
      />

      <!-- ... -->

      <!-- logic -->

      <SelectField
        v-model="condition.logic"
        is-mandatory
        :options="logics(condition.name)"
        class="col"
      />

      <!-- ... -->

      <!-- value -->

      <TextField v-model="condition.value" is-mandatory class="col" />

      <!-- ... -->

      <!-- remove -->

      <div class="action">
        <BaseIcon
          v-tooltip.top="'remove row'"
          name="eva-close"
          color="red"
          @click="removeCondition(idx)"
        />
      </div>

      <!-- ... -->
    </div>

    <!-- ... -->
  </div>
</template>

<script>
import SecondaryHeader from "./SecondaryHeader.vue";
import TertiaryHeader from "./TertiaryHeader.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import Conditions from "@/helpers/conditions.js";
import { startCase, cloneDeep, lowerCase } from "lodash-es";

export default {
  name: "ConditionBuilder",

  components: { SecondaryHeader, TertiaryHeader, SelectField, TextField },

  props: {
    fields: {
      type: Array,
      default: () => [],
    },

    conditions: {
      type: Array,
      default: () => [],
    },

    groupLogic: {
      type: String,
      default: "ALL",
    },
  },

  data() {
    return {
      groupLogics: [
        {
          id: this.$nano.id(),
          label: "any",
          value: "ANY",
        },
        {
          id: this.$nano.id(),
          label: "all",
          value: "ALL",
        },
      ],
      conditionGroups: [
        {
          id: this.$nano.id(),
          conditions: [
            {
              id: this.$nano.id(),
              name: "",
              logic: "",
              value: "",
            },
          ],
          groupLogic: "ANY",
          groupsLogic: "AND",
        },
      ],
      conditionsClone: [],
    };
  },

  computed: {
    _fields() {
      return this.fields.map((field) => ({
        id: field.id,
        label: field.label || startCase(lowerCase(field.type)),
        value: field.id,
        type: field.type,
      }));
    },
  },

  watch: {
    conditionsClone: {
      deep: true,
      handler() {
        this.$emit("update:conditions", this.conditionsClone);
      },
    },
    groupLogic: {
      deep: true,
      handler() {
        this.$emit("update:groupLogic", this.groupLogic);
      },
    },
  },

  created() {
    if (this.conditions.length) {
      this.conditionsClone = cloneDeep(this.conditions);
      return;
    }

    this.addCondition();
  },

  methods: {
    logics(fieldId) {
      if (!fieldId) {
        return [];
      }

      const dataType = this.fields.find((field) => field.id === fieldId)?.type;
      // return Conditions(dataType).map((condition) => ({
      //   id: this.$nano.id(),
      //   label: startCase(lowerCase(condition)),
      //   value: condition,
      // }));
      return Conditions(dataType).map((condition) => ({
        id: this.$nano.id(),
        label: `${startCase(lowerCase(condition.split(" ")[0]))} ${
          condition.split(" ")[1] ? condition.split(" ")[1] : ""
        }`,
        value: condition.split(" ")[0],
      }));
    },

    addCondition() {
      this.conditionsClone.push({
        id: this.$nano.id(),
        name: "",
        logic: "",
        value: "",
      });
    },

    removeCondition(conditionIdx) {
      this.conditionsClone.splice(conditionIdx, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
#condition-builder {
  .condition {
    display: flex;
    align-items: center;
    gap: 8px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    .action {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}
</style>
