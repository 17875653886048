<template>
  <div id="secondary-header">
    <div class="row items-center q-mb-md">
      <div class="col row items-center">
        <!-- before -->

        <span class="hint">
          <slot name="before"></slot>
        </span>

        <!-- ... -->

        <!-- item list -->

        <div class="item-list">
          <div class="row items-center">
            <div class="label">{{ selectedItem }}</div>
            <BaseIcon name="eva-chevron-down" inherit-color class="icon" />
          </div>

          <q-menu
            fit
            auto-close
            transition-show="scale"
            transition-hide="scale"
          >
            <ListItem
              v-for="item in items"
              :key="item.id"
              :is-selected="value === item.value"
              :label="item.label"
              class="text-capitalize"
              @click="$emit('input', item.value)"
            />
          </q-menu>
        </div>

        <!-- ... -->

        <!-- after -->

        <span class="hint">
          <slot name="after"></slot>
        </span>

        <!-- ... -->
      </div>
    </div>
  </div>
</template>

<script>
import ListItem from "@/components/common/ListItem.vue";

export default {
  name: "SecondaryHeader",

  components: { ListItem },

  props: {
    value: {
      type: String,
      default: "",
    },

    items: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    selectedItem() {
      return this.items.find((item) => item.value === this.value).label;
    },
  },
};
</script>

<style lang="scss" scoped>
#secondary-header {
  .hint {
    color: var(--icon-color);
  }

  .item-list {
    padding: 4px 4px 4px 8px;
    margin: 0px 8px;
    border: 1px solid var(--divider-color);
    border-radius: 4px;
    text-transform: capitalize;
    font-weight: 500;
    cursor: pointer;

    .label {
      flex: 1;
      color: var(--secondary);
      margin-right: 8px;
    }

    .icon {
      color: var(--icon-color-inverted);
    }
  }
}
</style>
