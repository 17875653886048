// export default {
//   START: {
//     label: "start",
//     icon: "mdi-flag",
//     color: "#2BCCBA",
//   },
//   END: {
//     label: "end",
//     icon: "mdi-flag",
//     color: "#FC5C65",
//   },
//   INTERNAL_USER: {
//     label: "internal user",
//     icon: "mdi-account",
//     color: "#45AAF2",
//   },
//   INTERNAL_USER_GROUP: {
//     label: "internal user group",
//     icon: "mdi-account-multiple",
//     color: "#A65EEA",
//   },
//   DYNAMIC_USER: {
//     label: "dynamic user",
//     icon: "mdi-account",
//     color: "#F368E0",
//   },
//   EXTERNAL_USER: {
//     label: "external user",
//     icon: "mdi-account",
//     color: "#4B7BEC",
//   },
//   CONDITION: {
//     label: "condition",
//     icon: "mdi-share-variant",
//     color: "#FD9644",
//   },
// };

export default {
  START: {
    label: "start",
    description: "Configure how to Initiate your workflow",
    icon: "mdi-flag",
    color: "#2BCCBA",
  },
  END: {
    label: "end",
    description: "Configure how your workflow ends",
    icon: "mdi-flag",
    color: "#FC5C65",
  },
  INTERNAL_ACTOR: {
    label: "internal actor",
    description: "An internal user acts on this workflow",
    icon: "mdi-account-multiple",
    color: "#A65EEA",
  },
  EXTERNAL_ACTOR: {
    label: "external actor",
    description: "An external user acts on this workflow",
    icon: "mdi-account",
    color: "#F368E0",
  },
  CONDITION: {
    label: "condition",
    description: "Apply a condition to this workflow",
    icon: "mdi-share-variant",
    color: "#fd9644",
  },
  RULES: {
    label: "rules",
    description: "Apply a rule to this workflow",
    icon: "mdi-source-branch",
    color: "#ffeb3b",
  },
  DATA_COMPARE: {
    label: "data compare",
    description: "Apply a data comparison to this workflow",
    icon: "mdi-database-sync",
    color: "#ffc107",
  },
  EMAIL_WATCHER: {
    label: "email watcher",
    description: "Continuously scans inbox for new messages",
    icon: "mdi-email-search-outline",
    color: "#267cb5",
  },
  WORKFLOW_CONNECTOR: {
    label: "Workflow Connector",
    description: "Connect to another workflow",
    icon: "mdi-file-tree-outline",
    color: "#EA22EB",
  },
  EXTERNAL_API: {
    label: "external API",
    description: "Connect to an external API",
    icon: "mdi-cog-sync-outline",
    color: "#f4428a",
  },
  GCP: {
    label: "GCP Storage",
    description: "Connect to a Google Cloud Storage",
    icon: "eva-google-outline",
    color: "#4285F4",
  },
  AWS: {
    label: "AWS Storage",
    description: "Connect to a Amazon Web Service Storage",
    icon: "mdi-aws",
    color: "#FF9900",
  },
  AZURE: {
    label: "Azure Storage",
    description: "Connect to a Microsoft Azure Storage",
    icon: "mdi-microsoft-azure",
    color: "#0080FF",
  },
  DROPBOX: {
    label: "Dropbox",
    description: "Connect to a Dropbox Storage",
    icon: "mdi-dropbox",
    color: "#3d9ae8",
  },
  ONEDRIVE: {
    label: "Onedrive",
    description: "Connect to a Onedrive Storage",
    icon: "mdi-microsoft-onedrive",
    color: "#28A8EA",
  },
};
