<template>
  <div id="security-access" class="field">
    <!-- form cntrols access -->
    <SingleChoiceField
      v-model="value.formEditAccess"
      label="Form Controls Access"
      :options-per-line="1"
      :options="formControlAccessOption"
      class="field q-mb-md"
    />

    <!-- form controls -->

    <MultiSelectField
      v-if="value.formEditAccess === 'PARTIAL' && singleCondition"
      v-model="value.formEditControls"
      label="Choose Form Fields To Edit"
      is-mandatory
      :options="formColumns"
      class="field q-mb-md"
    />

    <BaseScrollbar
      v-if="value.formEditAccess === 'PARTIAL' && !singleCondition"
      class="q-pb-sm"
    >
      <table>
        <thead>
          <tr>
            <th class="medium">Node User</th>
            <th class="medium">Form Fields</th>
            <th class="medium">Node Label</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="edit in value.formEditControls" :key="edit.id">
            <td>{{ edit.user }}</td>
            <td>
              <MultiSelectField
                v-model="edit.formFields"
                is-mandatory
                :options="formColumns"
              />
            </td>
            <td>
              <TextField v-model="edit.label" />
            </td>
          </tr>
        </tbody>
      </table>
    </BaseScrollbar>

    <!-- ... -->

    <!-- form visibility access -->

    <SingleChoiceField
      v-model="value.formVisibilityAccess"
      label="Form Visibility Access"
      :options-per-line="1"
      :options="formControlVisibilityOption"
      class="field q-mb-md"
    />

    <!-- form controls -->

    <MultiSelectField
      v-if="!value.formVisibilityAccess && singleCondition"
      v-model="value.formSecureControls"
      label="Choose Form Fields To Secure"
      is-mandatory
      :options="formColumns"
      class="field q-mb-md"
    />

    <BaseScrollbar
      v-if="!value.formVisibilityAccess && !singleCondition"
      class="q-pb-sm"
    >
      <table>
        <thead>
          <tr>
            <th class="medium">Node User</th>
            <th class="medium">Form Fields</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="edit in value.formSecureControls" :key="edit.id">
            <td>{{ edit.user }}</td>
            <td>
              <MultiSelectField
                v-model="edit.formFields"
                is-mandatory
                :options="formColumns"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </BaseScrollbar>

    <!-- ... -->
  </div>
</template>

<script>
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";

export default {
  name: "SecurityAccess",

  components: { SingleChoiceField, MultiSelectField, TextField },

  props: {
    value: {
      type: Object,
      default: () => {},
    },

    formColumns: {
      type: Array,
      default: () => [],
    },

    users: {
      type: Array,
      default: () => [],
    },

    userList: {
      type: Array,
      default: () => [],
    },

    blockType: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      formControlAccessOption: [
        {
          id: this.$nano.id(),
          label: "No Access",
          description: "Can't fill and edit the form field entries",
          value: "",
        },
        {
          id: this.$nano.id(),
          label: "Full Access",
          description: "Can fill and edit all the form field entries",
          value: "FULL",
        },
        {
          id: this.$nano.id(),
          label: "Specific Controls",
          description:
            "Can fill and edit only specific controls on form field entries",
          value: "PARTIAL",
        },
      ],
      formControlVisibilityOption: [
        {
          id: this.$nano.id(),
          label: "Full Access",
          description: "Can view all the form field entries",
          value: true,
        },
        {
          id: this.$nano.id(),
          label: "Secure Controls",
          description:
            "Secure specific controls visibility on the form field entries",
          value: false,
        },
      ],
      label: "",
      formEditControls: [],
      formSecureControls: [],
      singleCondition: false,
    };
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function () {
        this.$emit("input", this.value);
      },
    },

    users: {
      immediate: true,
      deep: true,
      handler: function () {
        // this.$emit("input", this.value);
        if (this.blockType !== "START" && this.users.length > 1) {
          this.singleCondition = false;
          this.value.formEditControls = this.value.formEditControls.filter(
            (control) => typeof control === "object"
          );
          this.value.formSecureControls = this.value.formSecureControls.filter(
            (control) => typeof control === "object"
          );
          this.users.forEach((item) => {
            let index = this.value.formEditControls.findIndex((name) => {
              return name.userId === item;
            });
            let email = this.userList.find((col) => col.value === item);
            if (index === -1 && email) {
              this.value.formEditControls.push({
                id: this.$nano.id(),
                userId: item,
                user: email.label,
                formFields: [],
                label: "",
              });
            }
          });

          this.users.forEach((item) => {
            let index = this.value.formSecureControls.findIndex((name) => {
              return name.userId === item;
            });
            let email = this.userList.find((col) => col.value === item);
            if (index === -1 && email) {
              this.value.formSecureControls.push({
                id: this.$nano.id(),
                userId: item,
                user: email.label,
                formFields: [],
              });
            }
          });
        } else {
          this.value.formEditControls = this.value.formEditControls.filter(
            (control) => typeof control === "string"
          );
          this.value.formSecureControls = this.value.formSecureControls.filter(
            (control) => typeof control === "string"
          );
          this.singleCondition = true;
        }
      },
    },
  },
};
</script>
<style lang="scss">
#security-access {
  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;

    tr {
      height: 40px;
    }

    th {
      padding: 4px 4px;
      text-align: left;
      font-weight: 500;
      text-transform: capitalize;

      &.extra-small {
        width: 20px;
      }

      &.small {
        width: 70px;
      }

      &.medium {
        width: 140px;
      }

      &.large {
        width: 210px;
      }

      &.actions {
        width: 48px;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
    }

    td {
      padding: 4px;
      // vertical-align: top;
      word-wrap: break-word;
    }
  }
}
</style>
