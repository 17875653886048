<template>
  <div id="email-initiator">
    <!-- <ValidationObserver ref="emailForm"> -->
    <ValidationProvider
      v-slot="{ errors }"
      name="Mail name"
      :rules="{ required: true }"
    >
      <SelectField
        v-model="value.mailSettingsId"
        is-mandatory
        label="Monitor Mail Messages From"
        :options="mailSettingsList"
        :is-clearable="true"
        :error="errors[0]"
        class="field q-mb-md"
      />
    </ValidationProvider>

    <MultipleChoiceField
      v-if="false"
      :value="mailTriggerConditions"
      label="Trigger Conditions"
      :options="conditions"
      :options-per-line="3"
      class="field q-mb-md"
      @input="updateTriggerConditions"
    />

    <FormFieldLabel label="Trigger Conditions" />

    <BaseSeparator class="q-mb-md" />

    <CheckboxField
      :value="hasMailSubject"
      label="Mail Subject"
      description="To check the mail subject"
      class="field q-mb-md"
      @input="updateMailSubject"
    />

    <template
      v-if="mailTriggerConditions.includes('MAIL_SUBJECT') || hasMailSubject"
    >
      <div class="row q-mb-md q-col-gutter-md">
        <TextField
          v-model="value.conditions.mailSubject"
          label="Mail Subject to monitor"
          class="field col-12"
        />
      </div>
    </template>

    <CheckboxField
      v-model="value.conditions.hasAttachment"
      label="Has Attachment"
      description="To check the attachment included"
      class="field q-mb-md"
    />

    <template
      v-if="
        mailTriggerConditions.includes('ATTACHMENT') ||
        value.conditions.hasAttachment
      "
    >
      <div class="row q-mb-md q-col-gutter-md">
        <TextField
          v-model="value.conditions.attachment.filename"
          label="Specific Filename"
          class="field col-4"
        />

        <SelectField
          v-model="value.conditions.attachment.fileType"
          label="Or Specific Format"
          :options="formatList"
          :is-clearable="true"
          class="field col-4"
        />

        <TextField
          v-model="value.conditions.attachment.ocrData"
          label="OCR content contains "
          class="field col-4"
        />
      </div>
    </template>

    <CheckboxField
      :value="hasMailContent"
      label="Mail Content"
      description="To check the mail Content"
      class="field q-mb-md"
      @input="updateMailContent"
    />

    <template
      v-if="mailTriggerConditions.includes('MAIL_CONTENT') || hasMailContent"
    >
      <div class="row q-mb-md q-col-gutter-md">
        <TextField
          v-model="value.conditions.mailContent"
          label="Specific Mail Content to Monitor"
          class="field col-12"
        />
      </div>
    </template>

    <ValidationProvider
      v-slot="{ errors }"
      name="email"
      :rules="{ email: true }"
    >
      <MultiSelectField
        v-model="value.conditions.fromAddress"
        label="From Mail Address"
        :options="userEmailList"
        new-option
        :email-rule="true"
        :is-clearable="true"
        class="field q-mb-md"
        :error="errors[0]"
      />
    </ValidationProvider>

    <!-- domain users -->
    <ValidationProvider
      v-slot="{ errors }"
      name="domain"
      :rules="{ domain: true }"
    >
      <MultiSelectField
        v-model="value.conditions.fromDomain"
        label="From Domain Name"
        is-mandatory
        :options="mailDomainList"
        new-option
        domain-rule
        :is-clearable="true"
        class="field q-mb-md"
        placeholder="example.com"
        :error="errors[0]"
      />
    </ValidationProvider>
    <!-- </ValidationObserver> -->
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import MultipleChoiceField from "@/components/common/form/multiple-choice-field/MultipleChoiceField.vue";
import { ValidationProvider } from "vee-validate";

export default {
  name: "EmailInitiator",

  components: {
    FormFieldLabel,
    TextField,
    SelectField,
    MultiSelectField,
    CheckboxField,
    MultipleChoiceField,
    // ValidationObserver,
    ValidationProvider,
  },

  props: {
    value: {
      type: Object,
      default: () => {},
    },

    formId: {
      type: Number,
      default: 0,
    },

    mailSettingsList: {
      type: Array,
      default: () => [],
    },

    mailTriggerConditions: {
      type: Array,
      default: () => [],
    },

    userEmailList: {
      type: Array,
      default: () => [],
    },

    mailDomainList: {
      type: Array,
      default: () => [],
    },

    hasMailSubject: {
      type: Boolean,
      default: false,
    },

    hasMailContent: {
      type: Boolean,
      default: false,
    },

    formComponent: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      conditions: [
        {
          id: this.$nano.id(),
          label: "Has Attachment",
          value: "ATTACHMENT",
        },
        {
          id: this.$nano.id(),
          label: "Has Mail Subject",
          value: "MAIL_SUBJECT",
        },
        {
          id: this.$nano.id(),
          label: "Has Mail Content",
          value: "MAIL_CONTENT",
        },
      ],
      condition: [],
      formatList: [
        {
          id: this.$nano.id(),
          label: "Pdf",
          value: "pdf",
        },
        {
          id: this.$nano.id(),
          label: "Docx",
          value: "docx",
        },
        {
          id: this.$nano.id(),
          label: "Jpeg",
          value: "jpeg",
        },
        {
          id: this.$nano.id(),
          label: "Xls",
          value: "xls",
        },
      ],
    };
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function () {
        this.$emit("input", this.value);
      },
    },
  },

  created() {},

  methods: {
    updateTriggerConditions(value) {
      this.$emit("update:mailTriggerConditions", value);
    },

    updateMailSubject(value) {
      if (this.formComponent) {
        this.$parent.$emit("update:hasMailSubject", value);
      } else {
        this.$emit("update:hasMailSubject", value);
      }
    },

    updateMailContent(value) {
      if (this.formComponent) {
        this.$parent.$emit("update:hasMailContent", value);
      } else {
        this.$emit("update:hasMailContent", value);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#email-initiator {
  .option-wrapper {
    border-top: 1px solid var(--divider-color);
  }

  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;

    tr {
      height: 48px;
    }

    th:not(.action) {
      padding: 4px 8px;
      text-align: left;
      font-weight: 500;
      text-transform: capitalize;

      &.medium {
        width: 120px;
      }

      &.large {
        width: 150px;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      &.action {
        width: 36px;
      }
    }

    td {
      padding: 4px;
      vertical-align: top;
    }
  }
}
</style>
<style lang="scss">
#sla-builder {
  #single-choice-field .options-wrapper {
    border-top: 0px !important;
    .option {
      padding-top: 5px !important;
    }
  }

  .action-header {
    border-bottom: 1px solid var(--divider-color);
    padding-bottom: 10px;
  }
}
</style>
