<template>
  <div>
    <ValidationProvider
      v-slot="{ errors }"
      name="groups"
      :rules="{ required: true }"
    >
      <!-- group -->

      <MultiSelectField
        label="groups"
        is-mandatory
        :value="groups"
        :options="groupList"
        :error="errors[0]"
        @input="updateGroups"
      />

      <!-- ... -->
    </ValidationProvider>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";

export default {
  name: "Groups",

  components: { ValidationProvider, MultiSelectField },

  props: {
    groups: {
      type: Array,
      required: true,
    },

    groupList: {
      type: Array,
      required: true,
    },
  },

  methods: {
    updateGroups(groups) {
      this.$parent.$emit("update:groups", groups);
    },
  },
};
</script>

<style lang="scss" scoped>
.options-wrapper {
  border-top: 1px solid var(--divider-color);
  padding-top: 16px;
}
</style>
