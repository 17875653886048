<template>
  <FormSection
    :icon="isActive ? 'mdi-poll' : 'mdi-poll-outline'"
    :is-active="isActive"
    v-on="$listeners"
  >
    <template #title>Business Hours</template>
    <template #description> Businesses to define operational hours </template>

    <div id="sla-settings">
      <MultipleChoiceField
        v-model="value.settings.workDays"
        label="Working Days"
        :options="workDays"
        :options-per-line="4"
        class="q-pb-md"
      />

      <FormFieldLabel label="Working Hours" class="action-header" />

      <div class="row q-pb-md">
        <div class="col-4">
          <TimeField
            v-model="value.settings.workHours.from"
            label="Start Time"
            class="q-mr-md"
            is-mandatory
          />
        </div>
        <div class="col-4">
          <TimeField
            v-model="value.settings.workHours.to"
            label="Closing Time"
            class="q-mr-md"
            is-mandatory
          />
        </div>
        <div class="col-12">
          <SelectField
            v-model="value.settings.timeZone"
            label="Work Time Zone"
            class="col q-mt-md"
            :is-searchable="true"
            :options="timeZones"
          />
        </div>
      </div>
    </div>
  </FormSection>
</template>

<script>
import FormSection from "@/components/common/item-builder/FormSection.vue";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import MultipleChoiceField from "@/components/common/form/multiple-choice-field/MultipleChoiceField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import TimeField from "@/components/common/form/time-field/TimeField.vue";
import { common } from "@/api/factory.js";
export default {
  name: "SLASettings",

  components: {
    FormSection,
    FormFieldLabel,
    MultipleChoiceField,
    SelectField,
    TimeField,
  },

  props: {
    value: {
      type: Object,
      default: () => {},
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      workDays: [
        {
          id: this.$nano.id(),
          label: "Sun",
          value: "0",
        },
        {
          id: this.$nano.id(),
          label: "Mon",
          value: "1",
        },
        {
          id: this.$nano.id(),
          label: "Tue",
          value: "2",
        },
        {
          id: this.$nano.id(),
          label: "Wed",
          value: "3",
        },
        {
          id: this.$nano.id(),
          label: "Thu",
          value: "4",
        },
        {
          id: this.$nano.id(),
          label: "Fri",
          value: "5",
        },
        {
          id: this.$nano.id(),
          label: "Sat",
          value: "6",
        },
      ],
      timeZones: [],
    };
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function () {
        this.$emit("input", this.value);
      },
    },
  },

  created() {
    this.getTimeZones();
  },

  methods: {
    async getTimeZones() {
      const { error, payload } = await common.getTimeZones();

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        this.timeZones =
          payload &&
          payload.map((row) => ({
            id: this.$nano.id(),
            label: row.displayName,
            value: row.id,
          }));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#sla-settings {
  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;

    tr {
      height: 48px;
    }

    th:not(.action) {
      padding: 4px 8px;
      text-align: left;
      font-weight: 500;
      text-transform: capitalize;

      &.medium {
        width: 120px;
      }

      &.large {
        width: 140px;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      &.action {
        width: 36px;
      }
    }

    td {
      padding: 4px;
      vertical-align: top;
    }
  }

  .action-header {
    border-bottom: 1px solid var(--divider-color);
    padding-bottom: 10px;
  }
}
</style>
<style lang="scss">
#single-choice-field.noborder .options-wrapper {
  border-top: 0px !important;
  padding-top: 0px !important;
}
</style>
