import { fabric } from "fabric";
import { startCase } from "lodash-es";
import { mapState } from "vuex";
import blockList from "../../../helpers/blockList.js";

export default {
  data() {
    return {
      svgIcons: {
        flag: "M14.4,6L14,4H5V21H7V14H12.6L13,16H20V6H14.4Z",
        user: "M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z",
        group:
          "M16 17V19H2V17S2 13 9 13 16 17 16 17M12.5 7.5A3.5 3.5 0 1 0 9 11A3.5 3.5 0 0 0 12.5 7.5M15.94 13A5.32 5.32 0 0 1 18 17V19H22V17S22 13.37 15.94 13M15 4A3.39 3.39 0 0 0 13.07 4.59A5 5 0 0 1 13.07 10.41A3.39 3.39 0 0 0 15 11A3.5 3.5 0 0 0 15 4Z",
        condition:
          "M18,16.08C17.24,16.08 16.56,16.38 16.04,16.85L8.91,12.7C8.96,12.47 9,12.24 9,12C9,11.76 8.96,11.53 8.91,11.3L15.96,7.19C16.5,7.69 17.21,8 18,8A3,3 0 0,0 21,5A3,3 0 0,0 18,2A3,3 0 0,0 15,5C15,5.24 15.04,5.47 15.09,5.7L8.04,9.81C7.5,9.31 6.79,9 6,9A3,3 0 0,0 3,12A3,3 0 0,0 6,15C6.79,15 7.5,14.69 8.04,14.19L15.16,18.34C15.11,18.55 15.08,18.77 15.08,19C15.08,20.61 16.39,21.91 18,21.91C19.61,21.91 20.92,20.61 20.92,19A2.92,2.92 0 0,0 18,16.08Z",
        connect:
          "M10.59,13.41C11,13.8 11,14.44 10.59,14.83C10.2,15.22 9.56,15.22 9.17,14.83C7.22,12.88 7.22,9.71 9.17,7.76V7.76L12.71,4.22C14.66,2.27 17.83,2.27 19.78,4.22C21.73,6.17 21.73,9.34 19.78,11.29L18.29,12.78C18.3,11.96 18.17,11.14 17.89,10.36L18.36,9.88C19.54,8.71 19.54,6.81 18.36,5.64C17.19,4.46 15.29,4.46 14.12,5.64L10.59,9.17C9.41,10.34 9.41,12.24 10.59,13.41M13.41,9.17C13.8,8.78 14.44,8.78 14.83,9.17C16.78,11.12 16.78,14.29 14.83,16.24V16.24L11.29,19.78C9.34,21.73 6.17,21.73 4.22,19.78C2.27,17.83 2.27,14.66 4.22,12.71L5.71,11.22C5.7,12.04 5.83,12.86 6.11,13.65L5.64,14.12C4.46,15.29 4.46,17.19 5.64,18.36C6.81,19.54 8.71,19.54 9.88,18.36L13.41,14.83C14.59,13.66 14.59,11.76 13.41,10.59C13,10.2 13,9.56 13.41,9.17Z",
        plus: "M19 11h-6V5a1 1 0 0 0-2 0v6H5a1 1 0 0 0 0 2h6v6a1 1 0 0 0 2 0v-6h6a1 1 0 0 0 0-2z",
        delete:
          "M21 6h-5V4.33A2.42 2.42 0 0 0 13.5 2h-3A2.42 2.42 0 0 0 8 4.33V6H3a1 1 0 0 0 0 2h1v11a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V8h1a1 1 0 0 0 0-2zM10 4.33c0-.16.21-.33.5-.33h3c.29 0 .5.17.5.33V6h-4zM18 19a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V8h12z M9 17a1 1 0 0 0 1-1v-4a1 1 0 0 0-2 0v4a1 1 0 0 0 1 1z M15 17a1 1 0 0 0 1-1v-4a1 1 0 0 0-2 0v4a1 1 0 0 0 1 1z",
        duplicate:
          "M18 21h-6a3 3 0 0 1-3-3v-6a3 3 0 0 1 3-3h6a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3zm-6-10a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1z M9.73 15H5.67A2.68 2.68 0 0 1 3 12.33V5.67A2.68 2.68 0 0 1 5.67 3h6.66A2.68 2.68 0 0 1 15 5.67V9.4h-2V5.67a.67.67 0 0 0-.67-.67H5.67a.67.67 0 0 0-.67.67v6.66a.67.67 0 0 0 .67.67h4.06z",
        settings:
          "M8.61 22a2.25 2.25 0 0 1-1.35-.46L5.19 20a2.37 2.37 0 0 1-.49-3.22 2.06 2.06 0 0 0 .23-1.86l-.06-.16a1.83 1.83 0 0 0-1.12-1.22h-.16a2.34 2.34 0 0 1-1.48-2.94L2.93 8a2.18 2.18 0 0 1 1.12-1.41 2.14 2.14 0 0 1 1.68-.12 1.93 1.93 0 0 0 1.78-.29l.13-.1a1.94 1.94 0 0 0 .73-1.51v-.24A2.32 2.32 0 0 1 10.66 2h2.55a2.26 2.26 0 0 1 1.6.67 2.37 2.37 0 0 1 .68 1.68v.28a1.76 1.76 0 0 0 .69 1.43l.11.08a1.74 1.74 0 0 0 1.59.26l.34-.11A2.26 2.26 0 0 1 21.1 7.8l.79 2.52a2.36 2.36 0 0 1-1.46 2.93l-.2.07A1.89 1.89 0 0 0 19 14.6a2 2 0 0 0 .25 1.65l.26.38a2.38 2.38 0 0 1-.5 3.23L17 21.41a2.24 2.24 0 0 1-3.22-.53l-.12-.17a1.75 1.75 0 0 0-1.5-.78 1.8 1.8 0 0 0-1.43.77l-.23.33A2.25 2.25 0 0 1 9 22a2 2 0 0 1-.39 0zM4.4 11.62a3.83 3.83 0 0 1 2.38 2.5v.12a4 4 0 0 1-.46 3.62.38.38 0 0 0 0 .51L8.47 20a.25.25 0 0 0 .37-.07l.23-.33a3.77 3.77 0 0 1 6.2 0l.12.18a.3.3 0 0 0 .18.12.25.25 0 0 0 .19-.05l2.06-1.56a.36.36 0 0 0 .07-.49l-.26-.38A4 4 0 0 1 17.1 14a3.92 3.92 0 0 1 2.49-2.61l.2-.07a.34.34 0 0 0 .19-.44l-.78-2.49a.35.35 0 0 0-.2-.19.21.21 0 0 0-.19 0l-.34.11a3.74 3.74 0 0 1-3.43-.57L15 7.65a3.76 3.76 0 0 1-1.49-3v-.31a.37.37 0 0 0-.1-.26.31.31 0 0 0-.21-.08h-2.54a.31.31 0 0 0-.29.33v.25a3.9 3.9 0 0 1-1.52 3.09l-.13.1a3.91 3.91 0 0 1-3.63.59.22.22 0 0 0-.14 0 .28.28 0 0 0-.12.15L4 11.12a.36.36 0 0 0 .22.45z M12 15.5a3.5 3.5 0 1 1 3.5-3.5 3.5 3.5 0 0 1-3.5 3.5zm0-5a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0-1.5-1.5z",
        alert:
          "M13,13H11V7H13M13,17H11V15H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z",
        storage:
          "M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M15.68,15C15.34,13.3 13.82,12 12,12C10.55,12 9.3,12.82 8.68,14C7.17,14.18 6,15.45 6,17A3,3 0 0,0 9,20H15.5A2.5,2.5 0 0,0 18,17.5C18,16.18 16.97,15.11 15.68,15Z",
        robot:
          "M12,2A2,2 0 0,1 14,4C14,4.74 13.6,5.39 13,5.73V7H14A7,7 0 0,1 21,14H22A1,1 0 0,1 23,15V18A1,1 0 0,1 22,19H21V20A2,2 0 0,1 19,22H5A2,2 0 0,1 3,20V19H2A1,1 0 0,1 1,18V15A1,1 0 0,1 2,14H3A7,7 0 0,1 10,7H11V5.73C10.4,5.39 10,4.74 10,4A2,2 0 0,1 12,2M7.5,13A2.5,2.5 0 0,0 5,15.5A2.5,2.5 0 0,0 7.5,18A2.5,2.5 0 0,0 10,15.5A2.5,2.5 0 0,0 7.5,13M16.5,13A2.5,2.5 0 0,0 14,15.5A2.5,2.5 0 0,0 16.5,18A2.5,2.5 0 0,0 19,15.5A2.5,2.5 0 0,0 16.5,13Z",
        rules:
          "M13,14C9.64,14 8.54,15.35 8.18,16.24C9.25,16.7 10,17.76 10,19A3,3 0 0,1 7,22A3,3 0 0,1 4,19C4,17.69 4.83,16.58 6,16.17V7.83C4.83,7.42 4,6.31 4,5A3,3 0 0,1 7,2A3,3 0 0,1 10,5C10,6.31 9.17,7.42 8,7.83V13.12C8.88,12.47 10.16,12 12,12C14.67,12 15.56,10.66 15.85,9.77C14.77,9.32 14,8.25 14,7A3,3 0 0,1 17,4A3,3 0 0,1 20,7C20,8.34 19.12,9.5 17.91,9.86C17.65,11.29 16.68,14 13,14M7,18A1,1 0 0,0 6,19A1,1 0 0,0 7,20A1,1 0 0,0 8,19A1,1 0 0,0 7,18M7,4A1,1 0 0,0 6,5A1,1 0 0,0 7,6A1,1 0 0,0 8,5A1,1 0 0,0 7,4M17,6A1,1 0 0,0 16,7A1,1 0 0,0 17,8A1,1 0 0,0 18,7A1,1 0 0,0 17,6Z",
        externalAPI:
          "M11.7 20H11.3L10.9 17.4C9.7 17.2 8.7 16.5 7.9 15.6L5.5 16.6L4.7 15.3L6.8 13.7C6.4 12.5 6.4 11.3 6.8 10.1L4.7 8.7L5.5 7.4L7.9 8.4C8.7 7.5 9.7 6.9 10.9 6.6L11.2 4H12.7L13.1 6.6C14.3 6.8 15.4 7.5 16.1 8.4L18.5 7.4L19.3 8.7L17.2 10.2C17.4 10.8 17.5 11.4 17.5 12H18C18.5 12 19 12.1 19.5 12.2V12L19.4 11L21.5 9.4C21.7 9.2 21.7 9 21.6 8.8L19.6 5.3C19.5 5 19.3 5 19 5L16.5 6C16 5.6 15.4 5.3 14.8 5L14.4 2.3C14.5 2.2 14.2 2 14 2H10C9.8 2 9.5 2.2 9.5 2.4L9.1 5.1C8.5 5.3 8 5.7 7.4 6L5 5C4.7 5 4.5 5 4.3 5.3L2.3 8.8C2.2 9 2.3 9.2 2.5 9.4L4.6 11L4.5 12L4.6 13L2.5 14.7C2.3 14.9 2.3 15.1 2.4 15.3L4.4 18.8C4.5 19 4.7 19 5 19L7.5 18C8 18.4 8.6 18.7 9.2 19L9.6 21.7C9.6 21.9 9.8 22.1 10.1 22.1H12.6C12.1 21.4 11.9 20.7 11.7 20M16 12.3V12C16 9.8 14.2 8 12 8S8 9.8 8 12C8 14.2 9.8 16 12 16C12.7 14.3 14.2 12.9 16 12.3M10 12C10 10.9 10.9 10 12 10S14 10.9 14 12 13.1 14 12 14 10 13.1 10 12M18 14.5V13L15.8 15.2L18 17.4V16C19.4 16 20.5 17.1 20.5 18.5C20.5 18.9 20.4 19.3 20.2 19.6L21.3 20.7C22.5 18.9 22 16.4 20.2 15.2C19.6 14.7 18.8 14.5 18 14.5M18 21C16.6 21 15.5 19.9 15.5 18.5C15.5 18.1 15.6 17.7 15.8 17.4L14.7 16.3C13.5 18.1 14 20.6 15.8 21.8C16.5 22.2 17.2 22.5 18 22.5V24L20.2 21.8L18 19.5V21Z",
        dataCompare:
          "M19,12V13.5A4,4 0 0,1 23,17.5C23,18.32 22.75,19.08 22.33,19.71L21.24,18.62C21.41,18.28 21.5,17.9 21.5,17.5A2.5,2.5 0 0,0 19,15V16.5L16.75,14.25L19,12M19,23V21.5A4,4 0 0,1 15,17.5C15,16.68 15.25,15.92 15.67,15.29L16.76,16.38C16.59,16.72 16.5,17.1 16.5,17.5A2.5,2.5 0 0,0 19,20V18.5L21.25,20.75L19,23M12,3C16.42,3 20,4.79 20,7C20,9.21 16.42,11 12,11C7.58,11 4,9.21 4,7C4,4.79 7.58,3 12,3M4,9C4,11.21 7.58,13 12,13C13.11,13 14.17,12.89 15.14,12.68C14.19,13.54 13.5,14.67 13.18,15.96L12,16C7.58,16 4,14.21 4,12V9M20,9V11H19.5L18.9,11.03C19.6,10.43 20,9.74 20,9M4,14C4,16.21 7.58,18 12,18L13,17.97C13.09,19.03 13.42,20 13.95,20.88L12,21C7.58,21 4,19.21 4,17V14Z",
        emailWatcher:
          "M3,4H19A2,2 0 0,1 21,6V10.82C20,9.85 18.7,9.23 17.31,9.06L19,8V6L11,11L3,6V8L10.62,12.76C10.21,13.62 10,14.55 10,15.5C10,17.18 10.65,18.79 11.81,20H3A2,2 0 0,1 1,18V6C1,4.89 1.89,4 3,4M16.5,11C19,11 21,13 21,15.5C21,16.38 20.75,17.21 20.31,17.9L23.39,21L22,22.39L18.88,19.32C18.19,19.75 17.37,20 16.5,20C14,20 12,18 12,15.5C12,13 14,11 16.5,11M16.5,13A2.5,2.5 0 0,0 14,15.5A2.5,2.5 0 0,0 16.5,18A2.5,2.5 0 0,0 19,15.5A2.5,2.5 0 0,0 16.5,13Z",
        connector:
          "M8 8v8m0-8a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 8a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm8-8a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 0a4 4 0 0 1-4 4h-1a3 3 0 0 0-3 3",
      },
      wrapperBgColorLight: "#ffffff",
      wrapperBgColorDark: "#1d2129",
      iconColorLight: "#64748b",
      iconColorDark: "#94a3b8",
      descriptionColorLight: "#94a3b8",
      descriptionColorDark: "#64748b",
      redColorLight: "#f44336",
      redColorDark: "#ef5350",
      chipBgColorLight: "#f1f5f9",
      chipBgColorDark: "#2a303a",
      chipTextColorLight: "#1e293b",
      chipTextColorDark: "#cbd5e1",
    };
  },

  computed: {
    ...mapState(["darkTheme"]),
  },

  methods: {
    mapIcon(blockType) {
      const iconMap = {
        START: this.svgIcons.flag,
        END: this.svgIcons.flag,
        INTERNAL_ACTOR: this.svgIcons.group,
        EXTERNAL_ACTOR: this.svgIcons.user,
        CONDITION: this.svgIcons.condition,
        RULES: this.svgIcons.rules,
        DATA_COMPARE: this.svgIcons.dataCompare,
        EMAIL_WATCHER: this.svgIcons.emailWatcher,
        EXTERNAL_API: this.svgIcons.externalAPI,
        GCP: this.svgIcons.storage,
        AWS: this.svgIcons.storage,
        AZURE: this.svgIcons.storage,
        DROPBOX: this.svgIcons.storage,
        ONEDRIVE: this.svgIcons.storage,
        WORKFLOW_CONNECTOR: this.svgIcons.connector,
      };

      return iconMap[blockType];
    },

    /* wrapper */

    createWrapper(blockType) {
      return new fabric.Rect({
        top: 0,
        left: 0,
        width: 175,
        height: 90,
        fill: this.darkTheme
          ? this.wrapperBgColorDark
          : this.wrapperBgColorLight,
        rx: 4,
        stroke: blockList[blockType].color,
        strokeWidth: 0,
        objectCaching: false,
      });
    },

    /* ... */

    /* avatar */

    createAvatar(blockType) {
      const bg = new fabric.Circle({
        top: 0,
        left: 0,
        radius: 10,
        fill: blockList[blockType].color,
        objectCaching: false,
      });

      const icon = new fabric.Path(this.mapIcon(blockType), {
        fill: "#ffffff",
      });

      icon.scaleToWidth(12);
      icon.scaleToHeight(12);

      icon.left = (36 - icon.width) / 4;
      icon.top = (36 - icon.height) / 4;

      return new fabric.Group([bg, icon], {
        top: 12,
        left: 10,
      });
    },

    /* ... */

    /* title */

    createTitle(blockType, blockLabel) {
      //console.log(blockList);
      const title = new fabric.Text(startCase(blockList[blockType].label), {
        fontFamily: "Outfit",
        fontSize: 12,
        fontWeight: 500,
        left: 0,
        top: 0,
        fill: blockList[blockType].color,
        objectCaching: false,
      });

      const type = new fabric.Text(blockLabel, {
        fontFamily: "Outfit",
        fontSize: 10,
        left: 0,
        top: 20,
        fill: this.darkTheme
          ? this.descriptionColorDark
          : this.descriptionColorLight,
        objectCaching: false,
      });

      return new fabric.Group([title, type], {
        top: 14,
        left: 38,
      });
    },

    /* ... */

    /* connect icon */

    createConnectIcon() {
      const icon = new fabric.Path(this.svgIcons.connect, {
        top: 14,
        left: 130,
        fill: this.darkTheme ? this.iconColorDark : this.iconColorLight,
        hoverCursor: "pointer",
        type: "connectBlockBtn",
        objectCaching: false,
      });

      icon.scaleToWidth(12);
      icon.scaleToHeight(12);

      return icon;
    },

    /* ... */

    /* add icon */

    createAddIcon() {
      const icon = new fabric.Path(this.svgIcons.plus, {
        top: 14,
        left: 150,
        fill: this.darkTheme ? this.iconColorDark : this.iconColorLight,
        hoverCursor: "pointer",
        type: "addBlockBtn",
      });

      icon.scaleToWidth(12);
      icon.scaleToHeight(12);

      return icon;
    },

    /* ... */

    /* separator */

    createSeparator(coordinates) {
      return new fabric.Line(coordinates, {
        stroke: "#64748b26",
        strokeWidth: 1,
        objectCaching: false,
      });
    },

    /* ... */

    /* chips */

    /* alert icon */

    createAlertIcon() {
      const icon = new fabric.Path(this.svgIcons.alert, {
        top: 66,
        left: 12,
        fill: this.darkTheme ? this.redColorDark : this.redColorLight,
        objectCaching: false,
      });

      icon.scaleToWidth(12);
      icon.scaleToHeight(12);

      return icon;
    },

    /* ... */

    /* settings icon */

    createSettingsIcon() {
      const icon = new fabric.Path(this.svgIcons.settings, {
        top: 66,
        left: 100,
        fill: this.darkTheme ? this.iconColorDark : this.iconColorLight,
        hoverCursor: "pointer",
        type: "blockSettingsBtn",
        objectCaching: false,
      });

      icon.scaleToWidth(12);
      icon.scaleToHeight(12);

      return icon;
    },

    /* ... */

    /* duplicate icon */

    createDuplicateIcon() {
      const icon = new fabric.Path(this.svgIcons.duplicate, {
        top: 66,
        left: 125,
        fill: this.darkTheme ? this.iconColorDark : this.iconColorLight,
        hoverCursor: "pointer",
        type: "duplicateBlockBtn",
        objectCaching: false,
      });

      icon.scaleToWidth(12);
      icon.scaleToHeight(12);

      return icon;
    },

    /* ... */

    /* delete icon */

    createDeleteIcon() {
      const icon = new fabric.Path(this.svgIcons.delete, {
        top: 66,
        left: 150,
        fill: this.darkTheme ? this.redColorDark : this.redColorLight,
        hoverCursor: "pointer",
        type: "deleteBlockBtn",
        objectCaching: false,
      });

      icon.scaleToWidth(12);
      icon.scaleToHeight(12);

      return icon;
    },

    /* ... */

    /* block */

    createBlock(blockType, blockLabel, blockId) {
      const avatar = this.createAvatar(blockType);
      const title = this.createTitle(blockType, blockLabel);
      const connectIcon = this.createConnectIcon();
      const addIcon = this.createAddIcon();

      const separator1 = this.createSeparator([12, 56, 170, 56]);

      const alertIcon = this.createAlertIcon();
      const settingsIcon = this.createSettingsIcon();
      const duplicateIcon = this.createDuplicateIcon();
      const deleteIcon = this.createDeleteIcon();

      const wrapper = this.createWrapper(blockType);

      return new fabric.Group(
        [
          wrapper,
          avatar,
          title,
          connectIcon,
          addIcon,
          separator1,
          alertIcon,
          settingsIcon,
          duplicateIcon,
          deleteIcon,
        ],
        {
          id: blockId || this.$nano.id(),
          top: 0,
          left: 0,
          type: "block",
          hasControls: false,
          hasBorders: false,
          subTargetCheck: true,
          color: blockList[blockType].color,
          icon: blockList[blockType].icon,
          objectCaching: false,
        }
      );

      // console.log(this.canvas.getObjects());
    },

    /* ... */
  },
};
