<template>
  <div id="rules-builder">
    <div v-for="(rule, idx) in rulesClone" :key="rule.id" class="rules-wrapper">
      <!-- header -->

      <PrimaryHeader
        :rule-idx="idx + 1"
        :rule="rule"
        @delete="deleteRule(idx)"
      />

      <!-- ... -->

      <BaseSeparator class="q-my-md" />

      <!-- condition builder -->

      <template v-if="rule.isConditionalRule">
        <ConditionBuilder
          :conditions.sync="rule.conditions"
          :group-logic.sync="rule.groupLogic"
          :fields="fields"
        />
      </template>

      <!-- ... -->
    </div>

    <!-- add rule buttons -->

    <template>
      <div id="add-rule-buttons">
        <div class="add-rule" @click="addRule">add rule</div>
      </div>
    </template>
    <!-- ... -->
  </div>
</template>

<script>
import PrimaryHeader from "./PrimaryHeader.vue";
import ConditionBuilder from "../condition-builder/ConditionBuilder.vue";
import { cloneDeep, isEmpty } from "lodash-es";

export default {
  name: "RulesSettings",

  components: {
    PrimaryHeader,
    ConditionBuilder,
  },

  props: {
    mode: {
      type: String,
      default: "NEW",
    },

    ruleConditions: {
      type: Array,
      default: () => [],
    },

    fields: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      rulesClone: [],
    };
  },

  watch: {
    rulesClone: {
      deep: true,
      handler() {
        this.$emit("update:ruleConditions", this.rulesClone);
      },
    },
  },

  created() {
    this.rulesClone = cloneDeep(this.ruleConditions);

    if (isEmpty(this.rulesClone)) {
      this.addRule(true);
    }
  },

  methods: {
    addRule(isConditionalRule = false) {
      this.rulesClone.push({
        id: this.$nano.id(),
        isConditionalRule,
        name: "",
        conditions: [],
        calculations: [],
        groupLogic: "ANY",
      });
    },

    deleteRule(ruleIdx) {
      this.rulesClone.splice(ruleIdx, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
#rules-builder {
  .rules-wrapper {
    border: 1px solid var(--divider-color);
    border-radius: 4px;
    padding: 16px;
    position: relative;
    margin-bottom: 16px;

    .delete-rule {
      position: absolute;
      top: 12px;
      right: 16px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  #add-rule-buttons {
    display: flex;
    align-items: center;

    .add-rule {
      padding: 8px 16px;
      background-color: var(--hover-bg-color);
      border-radius: 4px;
      font-weight: 500;
      text-transform: capitalize;

      &:not(:last-child) {
        margin-right: 8px;
      }

      &:hover {
        cursor: pointer;
        color: white;
        background-color: var(--secondary);
      }
    }
  }
}
</style>
