<template>
  <div>
    <!-- initiate mode -->

    <FormFieldLabel label="initiate mode" />

    <div class="options-wrapper">
      <!-- manual -->

      <RadioField
        :value="initiateMode === 'MANUAL'"
        label="Manual"
        description="A human intervention is required to initiate this workflow. Example: Filling a form"
        class="q-mb-md"
        @input="updateInitiateMode('MANUAL')"
      />

      <!-- ... -->

      <!-- automatic -->

      <RadioField
        :value="initiateMode === 'AUTOMATIC'"
        label="Automatic"
        description="System automatically initiates the workflow based on a trigger event such as email, file saved in a folder, DB updates, ETC"
        class="q-mb-md"
        @input="updateInitiateMode('AUTOMATIC')"
      />

      <!-- ... -->

      <!-- both -->

      <RadioField
        :value="initiateMode === 'MANUAL_AUTOMATIC'"
        label="Manual & Automatic"
        description="System automatically initiates the workflow but waits of a human to validate and approve it"
        @input="updateInitiateMode('MANUAL_AUTOMATIC')"
      />

      <!-- ... -->
    </div>

    <!-- ... -->
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import RadioField from "@/components/common/form/radio-field/RadioField.vue";

export default {
  name: "InitiateMode",

  components: { FormFieldLabel, RadioField },

  props: {
    initiateMode: {
      type: String,
      default: "MANUAL",
    },
  },

  methods: {
    updateInitiateMode(initiateMode) {
      this.$emit("update:initiateMode", initiateMode);
    },
  },
};
</script>

<style lang="scss" scoped>
.options-wrapper {
  border-top: 1px solid var(--divider-color);
  padding-top: 16px;
}
</style>
