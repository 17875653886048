<template>
  <div id="checklist-builder" class="field">
    <BaseScrollbar>
      <div>
        <FormFieldLabel label="Document Checklist" is-mandatory />
        <table>
          <thead>
            <tr>
              <th class="medium">Name</th>
              <th class="medium">Type</th>
              <th class="small">Required</th>
              <th class="small">Template</th>
              <th class="small">Settings</th>
              <th class="action">
                <BaseActionButton
                  is-flat
                  class="q-ml-xs"
                  color="secondary"
                  icon="eva-plus-outline"
                  no-border
                  @click="addRow"
                />
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(row, index) in value.fileCheckList" :key="index">
              <td>
                <TextField
                  v-model="value.fileCheckList[index]['name']"
                  :is-clearable="true"
                />
              </td>
              <td>
                <MultiSelectField
                  v-model="value.fileCheckList[index]['type']"
                  class="col"
                  :options="fileTypes"
                  :is-clearable="false"
                />
              </td>
              <td class="custom-center">
                <CheckboxField
                  v-model="value.fileCheckList[index]['required']"
                />
              </td>
              <td class="">
                <template v-if="value.fileCheckList[index]['template']">
                  <div class="row">
                    <BaseActionButton
                      v-tooltip.top="'Download'"
                      is-flat
                      icon="mdi-file-download"
                      color="primary"
                      no-border
                      class="col-auto"
                      @click="download(value.fileCheckList[index]['id'])"
                    />
                    <BaseActionButton
                      v-tooltip.top="'Remove'"
                      is-flat
                      icon="mdi-delete"
                      color="red"
                      no-border
                      class="col-auto"
                      @click="value.fileCheckList[index]['template'] = false"
                    />
                  </div>
                </template>
                <template v-else>
                  <BaseActionButton
                    v-tooltip.top="'Attach Template'"
                    is-flat
                    color="secondary"
                    icon="mdi-upload"
                    class="autoMargin"
                    @click="attachTemplate(index)"
                  />
                </template>
              </td>
              <td class="custom-center">
                <BaseActionButton
                  v-tooltip.top="'mandatory and reminder'"
                  is-flat
                  color="grey"
                  icon="eva-settings-outline"
                  class="autoMargin"
                  no-border
                  @click="setSettings(index)"
                />
              </td>
              <td class="action">
                <BaseActionButton
                  is-flat
                  color="red"
                  icon="eva-close-outline"
                  no-border
                  @click="removeRow(index)"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <input
          ref="attachments"
          type="file"
          class="hidden"
          @change="uploadAttachments"
        />
      </div>
      <div class="q-mt-md">
        <CheckboxField
          v-model="value.partial"
          label="Allow Submission"
          description="To submit the request without the required documents"
          class="field"
        />
      </div>
    </BaseScrollbar>

    <Modal
      v-model="settingsModal"
      width="70vw"
      height="80vh"
      has-footer
      no-padding
      @input="settingsModal = false"
    >
      <!-- title -->

      <template #title> Document Mandatory and Reminder Settings</template>

      <!-- ... -->

      <template #default>
        <Tabs v-model="tab" :tabs="tabs" class="q-mb-md" />

        <template v-if="tab === 'CONDITION'">
          <FormFieldLabel
            label='A document is marked as "required" when it meets
        predefined conditions, such as specific criteria and values. For
        example, if a project type is categorized as "High Risk," certain
        documents may be mandated for submission. The system evaluates these
        conditions and updates the documents status accordingly'
            transform=""
          />

          <CheckboxField
            v-model="visible"
            label="Show the optional documents"
            description="To display optional documents that may be relevant but are not mandatory for submission"
            class="field q-mb-md"
          />
        </template>
        <ConditionBuilder
          v-if="tab === 'CONDITION'"
          :conditions.sync="conditions"
          :group-logic.sync="groupLogic"
          :fields="formFields"
          class="field q-mb-md"
        />
        <div v-if="tab === 'REMINDER'">
          <FormFieldLabel
            label="A document reminder notification alerts users about pending or overdue document submissions. It typically includes details such as the document name, due date, and a call-to-action for timely completion. This helps ensure that required documents are submitted on schedule."
            transform=""
          />
          <table>
            <thead>
              <tr>
                <th class="medium">Duration</th>
                <th class="medium">Notify Users</th>
                <th class="medium">Form Column</th>
                <th class="small">Initiator</th>
                <th class="small">Superuser</th>
                <th class="small">Coordinator</th>
                <th class="action">
                  <BaseActionButton
                    is-flat
                    class="q-ml-xs"
                    color="secondary"
                    icon="eva-plus-outline"
                    no-border
                    @click="addRowReminder"
                  />
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(row, index) in reminder" :key="index">
                <td>
                  <div class="row">
                    <NumberField
                      v-model="reminder[index]['duration']"
                      :is-clearable="false"
                      class="col-4"
                    />
                    <SelectField
                      v-model="reminder[index]['type']"
                      :options="durationOption"
                      :is-clearable="false"
                      class="col"
                    />
                  </div>
                </td>
                <td>
                  <MultiSelectField
                    v-model="reminder[index]['notifyUsers']"
                    class="col"
                    :options="userList"
                    :is-clearable="false"
                  />
                </td>
                <td>
                  <MultiSelectField
                    v-model="reminder[index]['markCC']"
                    class="col"
                    :options="formFields"
                    :is-clearable="false"
                  />
                </td>
                <td class="custom-center">
                  <CheckboxField v-model="reminder[index]['initiator']" />
                </td>
                <td class="custom-center">
                  <CheckboxField v-model="reminder[index]['superuser']" />
                </td>
                <td class="custom-center">
                  <CheckboxField v-model="reminder[index]['coordinator']" />
                </td>
                <td class="action">
                  <BaseActionButton
                    is-flat
                    color="red"
                    icon="eva-close-outline"
                    no-border
                    @click="removeRowReminder(index)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <template #footer>
        <BaseButton label="Save" color="primary" @click="saveSettings" />
      </template>
    </Modal>
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import NumberField from "@/components/common/form/number-field/NumberField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import Modal from "@/components/common/popup/Modal.vue";
import ConditionBuilder from "@/components/common/condition-builder/ConditionBuilder.vue";
import Tabs from "@/components/common/tabs/Tabs.vue";
import axios from "axios";
import { fileSupport } from "@/helpers/file-format.js";

export default {
  name: "CheckList",

  components: {
    FormFieldLabel,
    TextField,
    CheckboxField,
    MultiSelectField,
    NumberField,
    SelectField,
    Modal,
    ConditionBuilder,
    Tabs,
  },

  props: {
    value: {
      type: Object,
      default: () => {},
    },

    workflowId: {
      type: String,
      default: "",
    },

    blockId: {
      type: String,
      default: "",
    },

    formFields: {
      type: Array,
      default: () => [],
    },

    userList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      tab: "CONDITION",
      tabs: [
        {
          id: this.$nano.id(),
          label: "Condition",
          value: "CONDITION",
        },
        {
          id: this.$nano.id(),
          label: "Reminder",
          value: "REMINDER",
        },
      ],
      fileTypes: [
        {
          id: this.$nano.id(),
          label: "Any",
          value: "any",
        },
        {
          id: this.$nano.id(),
          label: "PDF",
          value: "pdf",
        },
        {
          id: this.$nano.id(),
          label: "Images",
          value: "images",
        },
        {
          id: this.$nano.id(),
          label: "MS Office",
          value: "office",
        },
      ],
      durationOption: [
        {
          id: this.$nano.id(),
          label: "Minutes",
          value: "minutes",
        },
        {
          id: this.$nano.id(),
          label: "Hours",
          value: "hours",
        },
        {
          id: this.$nano.id(),
          label: "Days",
          value: "days",
        },
      ],
      rowIndex: null,
      settingsModal: false,
      conditions: [],
      groupLogic: "",
      visible: true,
      reminder: [],
    };
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function () {
        this.$emit("input", this.value);
      },
    },
  },

  mounted() {
    if (this.value.fileCheckList.length === 0) {
      this.addRow();
    }
  },

  methods: {
    addRow() {
      const model = {
        id: this.$nano.id(),
        name: "",
        required: false,
        type: [],
        template: false,
      };

      this.value.fileCheckList.push(model);
    },

    removeRow(rowIdx) {
      this.value.fileCheckList.splice(rowIdx, 1);
    },

    addRowReminder() {
      const model = {
        id: this.$nano.id(),
        duration: "",
        type: "",
        notifyUsers: [],
        markCC: [],
        initiator: false,
        superuser: false,
        coordinator: false,
      };

      this.reminder.push(model);
    },

    removeRowReminder(rowIdx) {
      this.reminder.splice(rowIdx, 1);
    },

    attachTemplate(index) {
      this.rowIndex = index;
      this.$refs.attachments.click();
    },

    download(id) {
      window.open(
        `${process.env.VUE_APP_API_URL}/workflow/viewChecklist?wId=${this.workflowId}&id=${id}`
      );
    },

    setSettings(index) {
      this.tab = "CONDITION";
      this.rowIndex = index;
      this.reminder = [];
      this.conditions = [];
      this.groupLogic = "ALL";
      this.visible = true;
      if (this.value.fileCheckList[index].conditions) {
        this.conditions = this.value.fileCheckList[index].conditions;
        this.groupLogic = this.value.fileCheckList[index].groupLogic;
        this.visible = this.value.fileCheckList[index].visible;
      }
      if (
        this.value.fileCheckList[index].reminder &&
        this.value.fileCheckList[index].reminder.length
      ) {
        this.reminder = this.value.fileCheckList[index].reminder;
        if (this.conditions.length === 0) {
          this.tab = "REMINDER";
        }
      } else {
        this.value.fileCheckList[index].reminder = [];
        this.addRowReminder();
      }
      this.settingsModal = true;
    },

    saveSettings() {
      let conditionRow = [];
      if (this.conditions.length) {
        conditionRow = this.conditions.filter(
          (row) => row.name && row.logic && row.value
        );
      }
      this.value.fileCheckList[this.rowIndex].conditions = conditionRow;
      this.value.fileCheckList[this.rowIndex].groupLogic = this.groupLogic;
      this.value.fileCheckList[this.rowIndex].visible = this.visible;
      let reminderRow = [];
      if (this.reminder.length) {
        reminderRow = this.reminder.filter(
          (row) =>
            row.duration &&
            row.type &&
            (row.notifyUsers.length ||
              row.markCC.length ||
              row.initiator ||
              row.superuser ||
              row.coordinator)
        );
      }
      reminderRow.forEach((row, idx) => {
        row.level = idx + 1;
      });
      this.value.fileCheckList[this.rowIndex].reminder = reminderRow;
      this.rowIndex = null;
      this.conditions = [];
      this.groupLogic = "";
      this.visible = true;
      this.reminder = [];
      this.settingsModal = false;
    },

    async uploadAttachments(e) {
      const file = e.target.files[0];
      const formData = new FormData();
      if (this.workflowId) {
        let row = {
          id: this.value.fileCheckList[this.rowIndex]["id"],
          name: this.value.fileCheckList[this.rowIndex]["name"],
          required: this.value.fileCheckList[this.rowIndex]["required"],
          type: this.value.fileCheckList[this.rowIndex]["type"],
        };
        formData.append("workflowId", this.workflowId);
        formData.append("activityId", this.blockId);
        formData.append("fileCheckList", JSON.stringify(row));
      }
      formData.append("file", file);

      let filename = e.target.files[0].name;
      const ext = filename.split(".").pop();
      if (!fileSupport(ext)) {
        this.$alert.info("This file format is not supported");
        return;
      }
      try {
        const response = await axios({
          url: `${process.env.VUE_APP_API_URL}/workflow/uploadChecklist`,
          method: "POST",
          data: formData,
          headers: {
            Token: this.$store.state.identity.token,
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        });

        const { status, data } = response;
        if (status !== 200) {
          throw "Invalid response";
        }
        this.$alert.success(`Template attached`);
        this.value.fileCheckList[this.rowIndex]["id"] = Number(data);
        this.value.fileCheckList[this.rowIndex]["template"] = true;
        this.rowIndex = null;
        e.target.value = "";
      } catch (error) {
        console.error(error);
        this.$alert.error(`error attaching file`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#checklist-builder {
  .option-wrapper {
    border-top: 1px solid var(--divider-color);
  }

  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;

    tr {
      height: 48px;
    }

    th:not(.action) {
      padding: 4px 8px;
      text-align: left;
      font-weight: 500;
      text-transform: capitalize;

      &.small {
        width: 60px;
      }

      &.medium {
        width: 180px;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      &.action {
        width: 36px;
      }
    }

    td {
      padding: 4px;
      .autoMargin {
        margin: auto;
      }
    }
    .custom-center {
      text-align: center;
    }
  }
}

#modal {
  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;

    tr {
      height: 48px;
    }

    th:not(.action) {
      padding: 4px 8px;
      text-align: left;
      font-weight: 500;
      text-transform: capitalize;

      &.small {
        width: 70px;
      }

      &.medium {
        width: 160px;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      &.action {
        width: 36px;
      }
    }

    td {
      padding: 4px;
      .autoMargin {
        margin: auto;
      }
    }
    .custom-center {
      text-align: center;
    }
  }
}
</style>
<style lang="scss">
#checklist-builder,
#modal {
  .custom-center {
    #checkbox-field .option,
    #action-btn.upload {
      display: unset !important;
    }
  }
}
</style>
