<template>
  <div id="workflow-builder">
    <WorkflowHeader
      :workflow-settings="workflow.settings"
      :is-loading="isLoading"
      :is-preview="isPreview"
      @settings="showSettings"
      @save="saveWorkflow"
      @preview="previewChange"
    />
    <div class="row">
      <div v-if="isPreview" class="col">
        <D3Network
          :blocks="workflow.blocks"
          :connections="workflow.rules"
          @blockDetails="blockDetails"
          @preview="previewChange"
        />
      </div>
      <div class="col">
        <WorkflowCanvas
          :workflow="workflow"
          :workflow-id="workflowId"
          @block="blocks"
        />
      </div>
    </div>
  </div>
</template>

<script>
import WorkflowHeader from "./components/WorkflowHeader.vue";
import WorkflowCanvas from "./components/workflow-canvas/WorkflowCanvas.vue";
import D3Network from "./components/workflow-canvas/D3Network.vue";

export default {
  name: "WorkflowBuilder",

  components: { WorkflowHeader, WorkflowCanvas, D3Network },

  props: {
    workflowId: {
      type: String,
      default: "",
    },
    workflow: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isPreview: false,
    };
  },

  methods: {
    showSettings() {
      this.$emit("settings");
    },

    saveWorkflow() {
      this.$emit("save");
    },

    blocks(block) {
      this.$emit("update", block);
    },

    previewChange() {
      if (this.isPreview) {
        this.isPreview = false;
      } else {
        this.isPreview = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#workflow-builder {
  min-height: calc(100vh - 68px);
  background-color: var(--body-bg-color);
}
</style>
