<template>
  <FormSection
    :icon="isActive ? 'mdi-file-chart' : 'mdi-file-chart-outline'"
    :is-active="isActive"
    v-on="$listeners"
  >
    <template #title>Report Scheduler</template>
    <template #description>
      To sent the workflow reports based on your preference
    </template>

    <div id="report-settings">
      <FormFieldLabel label="Report Type" class="action-header" />

      <SingleChoiceField
        v-model="value.durationType"
        :options="reportTypes"
        :options-per-line="2"
        class="col q-mb-md noborder"
      />

      <div class="row items-center q-col-gutter-md">
        <div class="col-6">
          <SelectField
            v-model="value.duration"
            label="Day"
            :options="dayTypes"
            class="q-mb-md"
          />
        </div>
        <div class="col-6">
          <TimeField v-model="value.time" label="Time" class="q-mb-md" />
        </div>
      </div>

      <MultiSelectField
        v-model="value.notifyTo"
        label="Notify Users"
        :options="userList"
        class="q-mb-md"
      />

      <CheckboxField
        v-model="value.runningRequest"
        label="Running Request"
        description="Generate report with the currently running request"
        class="field q-mb-md"
      />

      <CheckboxField
        v-model="value.completedRequest"
        label="Completed Request"
        description="Generate report with the completed request"
        class="field"
      />
    </div>
  </FormSection>
</template>

<script>
import FormSection from "@/components/common/item-builder/FormSection.vue";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import TimeField from "@/components/common/form/time-field/TimeField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
export default {
  name: "ReportSettings",

  components: {
    FormSection,
    FormFieldLabel,
    TimeField,
    SelectField,
    MultiSelectField,
    CheckboxField,
    SingleChoiceField,
  },

  props: {
    value: {
      type: Object,
      default: () => {},
    },
    userList: {
      type: Array,
      default: () => [],
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      reportTypes: [
        {
          id: this.$nano.id(),
          label: "Weekly",
          value: "Week",
        },
        {
          id: this.$nano.id(),
          label: "Monthly",
          value: "Month",
        },
      ],
    };
  },

  computed: {
    dayTypes() {
      if (this.value.durationType === "Week") {
        return [
          {
            id: this.$nano.id(),
            label: "Sunday",
            value: 0,
          },
          {
            id: this.$nano.id(),
            label: "Monday",
            value: 1,
          },
          {
            id: this.$nano.id(),
            label: "Tuesday",
            value: 2,
          },
          {
            id: this.$nano.id(),
            label: "Wednesday",
            value: 3,
          },
          {
            id: this.$nano.id(),
            label: "Thursday",
            value: 4,
          },
          {
            id: this.$nano.id(),
            label: "Friday",
            value: 5,
          },
          {
            id: this.$nano.id(),
            label: "Saturday",
            value: 6,
          },
        ];
      } else {
        let month = [];
        for (let i = 1; i <= 30; i++) {
          month.push({
            id: this.$nano.id(),
            label: i,
            value: i,
          });
        }
        return month;
      }
    },
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function () {
        this.$emit("input", this.value);
      },
    },
  },

  created() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
#report-settings {
  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;

    tr {
      height: 48px;
    }

    th:not(.action) {
      padding: 4px 8px;
      text-align: left;
      font-weight: 500;
      text-transform: capitalize;

      &.medium {
        width: 120px;
      }

      &.large {
        width: 140px;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      &.action {
        width: 36px;
      }
    }

    td {
      padding: 4px;
      vertical-align: top;
    }
  }

  .action-header {
    border-bottom: 1px solid var(--divider-color);
    padding-bottom: 10px;
  }
}
</style>
<style lang="scss">
#single-choice-field.noborder .options-wrapper {
  border-top: 0px !important;
  padding-top: 0px !important;
}
</style>
