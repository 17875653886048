<template>
  <FormSection
    :icon="isActive ? 'mdi-grid' : 'mdi-grid'"
    :is-active="isActive"
    v-on="$listeners"
  >
    <template #title>Kanban View Settings</template>
    <template #description>
      Configure the fields / tags that you would like to use for indexing
      documents, making it easier to save and search them in the future
    </template>

    <!-- fields -->

    <!-- <ValidationProvider v-slot="{ errors }" :rules="{ required: true }"> -->
    <KanbanBuilder
      :value="kanbanCards"
      :workflow="workflow"
      class="q-mb-lg"
      @input="updateFields"
    />
    <!-- :error="errors[0]" -->
    <!-- </ValidationProvider> -->

    <!-- ... -->
  </FormSection>
</template>

<script>
// import { ValidationProvider } from "vee-validate";
import FormSection from "@/components/common/item-builder/FormSection.vue";
import KanbanBuilder from "./components/kanban-builder/KanbanBuilder.vue";

export default {
  name: "KanbanViewSettings",

  components: {
    FormSection,

    // ValidationProvider,
    KanbanBuilder,
  },

  props: {
    kanbanCards: {
      type: Array,
      required: true,
    },

    isActive: {
      type: Boolean,
      default: false,
    },

    mode: {
      type: String,
      default: "NEW",
    },

    workflow: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {};
  },

  methods: {
    updateFields(fields) {
      // console.log(fields);
      // console.log(JSON.stringify(fields));
      this.$emit("update:kanbanCards", fields);
    },
  },
};
</script>

<style lang="scss" scoped></style>
