<template>
  <div id="other-cards">
    <div
      v-for="card in kanbanCards"
      :key="card._id"
      class="card row items-center"
    >
      <div class="col row">
        <!-- <BaseIcon name="mdi-label-outline" /> -->
        <div>{{ card.name }}</div>
      </div>

      <div style="width: 100px">{{ card.isDisplayPortal ? "Yes" : "No" }}</div>

      <div style="width: 100px">{{ card.color }}</div>

      <div style="width: 140px">{{ card.workflowNode }}</div>

      <BaseActionButton
        v-tooltip:secondary.left="'edit card'"
        is-flat
        color="secondary"
        icon="eva-edit-outline"
        no-border
        @click="handleEdit(card._id)"
      />

      <BaseActionButton
        v-tooltip:red.right="'delete card'"
        is-flat
        color="red"
        icon="eva-close-outline"
        no-border
        @click="handleDelete(card._id)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "KanbanCards",

  props: {
    kanbanCards: {
      type: Array,
      required: true,
    },
  },

  methods: {
    handleEdit(cardId) {
      this.$emit("edit", cardId);
    },

    handleDelete(cardId) {
      this.$emit("delete", cardId);
    },
  },
};
</script>

<style lang="scss" scoped>
#other-cards {
  .card {
    margin-bottom: 8px;
  }
}
</style>
