<template>
  <div id="prediction-builder">
    <div class="options-wrapper">
      <FormFieldLabel
        label="ML predictions are integrated into the workflow by analyzing data to
      forecast outcomes or guide decisions. Predictions are generated through
      trained models and trigger specific actions within the process. Continuous
      monitoring and feedback ensure that predictions remain accurate and
      relevant over time."
        transform=""
        class="field"
      />

      <CheckboxField
        v-model="value.hasPrediction"
        label="Prediction Required"
        description="AI auto prediction required for this stage"
        class="field"
      />

      <!-- <CheckboxField
          v-model="value.hasUser"
          label="User"
          description="Include my action to train data"
          class="field"
        /> -->

      <MultiSelectField
        v-if="value.hasPrediction"
        v-model="value.predictionFields"
        label="Prediction Fields"
        :options="formFields"
        tooltip="
          Configure prediction fields to include input features essential for model accuracy, such as historical data points and relevant metrics. Set up output fields to capture model predictions and their confidence levels. Ensure that these fields are aligned with the workflow's requirements for effective decision-making and integration
        "
        class="field"
      />
    </div>
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";

export default {
  name: "AIPrediction",

  components: {
    FormFieldLabel,
    MultiSelectField,
    CheckboxField,
  },

  props: {
    value: {
      type: Object,
      default: () => {},
    },

    formFields: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
#prediction-builder {
  .options-wrapper {
    padding: 0px 15px;
  }

  .field {
    margin-top: 16px;
  }
}
</style>
