<template>
  <div id="action-bar">
    <Tabs :value="value" :tabs="tabs" color="secondary" @input="handleInput" />

    <q-space />

    <BaseButton
      icon="eva-save-outline"
      label="save"
      class="q-ml-md"
      :is-loading="isLoading"
      @click="$emit('save')"
    />
  </div>
</template>

<script>
import Tabs from "@/components/common/tabs/Tabs.vue";

export default {
  name: "ActionBar",

  components: { Tabs },

  props: {
    value: {
      type: String,
      required: true,
    },

    tabs: {
      type: Array,
      required: true,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    handleInput(tab) {
      this.$emit("input", tab);
    },
  },
};
</script>

<style lang="scss" scoped>
#action-bar {
  padding: 0px 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--divider-color);
  background-color: var(--component-bg-color);
}
</style>
