<template>
  <div id="workflow-publish">
    <!-- action bar -->

    <ActionBar v-model="tab" :tabs="tabs" @save="saveSettings" />

    <!-- ... -->

    <!-- settings -->

    <BaseScrollbar height="calc(100vh - 138px)">
      <div class="row q-pb-xl">
        <!-- publish options -->

        <PublishOptions
          ref="PUBLISH"
          :mode="mode"
          :publish-option.sync="publishOption"
          :publish-schedule.sync="publishSchedule"
          :unpublish-schedule.sync="unpublishSchedule"
          :is-active="tab === 'PUBLISH'"
          @click="tab = 'PUBLISH'"
        />

        <!-- ... -->
      </div>
    </BaseScrollbar>

    <!-- ... -->
  </div>
</template>

<script>
import ActionBar from "../components/ActionBar.vue";
import { cloneDeep } from "lodash-es";
import PublishOptions from "./components/publish-options/PublishOptions.vue";

export default {
  name: "WorkflowPublish",

  components: {
    ActionBar,
    PublishOptions,
  },

  props: {
    workflowSettings: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      mode: "NEW",
      tab: "PUBLISH",
      tabs: [
        {
          id: this.$nano.id(),
          label: "publish",
          value: "PUBLISH",
        },
      ],

      // publish

      publishOption: "",
      publishSchedule: "",
      unpublishSchedule: "",
    };
  },

  watch: {
    tab() {
      this.$refs[this.tab].$el.scrollIntoView({ behavior: "smooth" });
    },

    workflowSettings: {
      immediate: true,
      deep: true,
      handler() {
        const workflowSettings = cloneDeep(this.workflowSettings);

        this.publishOption = workflowSettings.publish.publishOption;
        this.publishSchedule = workflowSettings.publish.publishSchedule;
        this.unpublishSchedule = workflowSettings.publish.unpublishSchedule;
      },
    },
  },

  methods: {
    saveSettings() {
      const workflowSettings = cloneDeep(this.workflowSettings);

      workflowSettings.publish.publishOption = this.publishOption;
      workflowSettings.publish.publishSchedule = this.publishSchedule;
      workflowSettings.publish.unpublishSchedule = this.unpublishSchedule;

      this.$emit("update", workflowSettings);
    },
  },
};
</script>

<style lang="scss" scoped></style>
