import { render, staticRenderFns } from "./ColorField.vue?vue&type=template&id=334d67c0&scoped=true&"
import script from "./ColorField.vue?vue&type=script&lang=js&"
export * from "./ColorField.vue?vue&type=script&lang=js&"
import style0 from "./ColorField.vue?vue&type=style&index=0&id=334d67c0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "334d67c0",
  null
  
)

export default component.exports
import QMenu from 'quasar/src/components/menu/QMenu.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QMenu});qInstall(component, 'directives', {ClosePopup});
